<template>
  <v-card elevation="3" style="padding: 0 10px 0 10px;">
    <v-row class="itemPageVrow">
      <v-col cols="12" class="tac itemListH1">
        <h1 class="fontNotoSans700 itemListH1" style="color: #454545;margin-top: 20px;">주문목록</h1>
      </v-col>

      <v-col cols="12" style="padding-bottom:0;">
        <table class="jogunDateTable">
          <tbody>
            <tr>
              <td class="jumunJogunSelectTd">입고일</td>
              <td class="jumunJogunSelectSecondTd">
                <div style="display: flex; align-items: center;">
                  <v-icon @click="decrementDate">{{ mdiChevronLeft }}</v-icon>

                  <v-text-field type="date" variant="outlined" v-model="itemIpgoDate" density="compact" hide-details
                    shaped></v-text-field>

                  <v-icon @click="incrementDate">{{ mdiChevronRight }}</v-icon>
                </div>
              </td>
              <td class=""></td>
              <td></td>
              <td class="tar">
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mJumunIpgoMsg" style="padding-top:0; padding-bottom: 20px;">
        <h2 class="fontNotoSans700 itemListH1" style="color: #454545;">{{ itemIpgoDate }} 입고품목</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" class="jumunTabs">
      <v-tab value="one"><span class="fontNotoSans700">주문대기</span></v-tab>
      <v-tab value="two"><span class="fontNotoSans700">주문신청</span></v-tab>
      <v-tab value="three"><span class="fontNotoSans700">접수완료</span></v-tab>
      <v-tab value="four"><span class="fontNotoSans700">주문반려</span></v-tab>
    </v-tabs>


    <v-card-text style="padding: 0;" class="jumunCardText">
      <v-window v-model="tab">
        <v-window-item value="one">
          <div v-if="jumun1.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>

          <table v-if="jumun1.length !== 0" class="jumunCtrlTb">
            <tbody>
              <tr>
                <td>
                  <v-checkbox v-model="selectJumun1" @change="toggleSelectJumun1" label="전체선택" hide-details
                    class="fontNotoSans400" color="error" />
                </td>
                <td>
                  <button @click="jumunStatus(0)" class="jumunCancelBtn fontNotoSans700">
                    주문취소
                  </button>
                  <button @click="jumunStatus(2)" class="jumunOkBtn fontNotoSans700">
                    주문신청
                  </button>

                </td>
              </tr>
            </tbody>
          </table>

          <template v-if="jumun1.length !== 0">

            <v-data-table v-if="isMobile" max-height="900px" fixed-header density="compact" :headers="mHeaders"
              :items="jumun1" item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;">{{ item.ij_name || '' }}
                    <span v-bind:style="{ marginLeft: '3px', color: getDdayDate(item.iu_Dday+1) >= itemIpgoDate ? 'red' : 'black', 'font-weight': getDdayDate(item.iu_Dday+1) >= itemIpgoDate ? '700' : '400' }">
                        ( D-{{ item.iu_Dday }} )
                    </span>
                  </td>
                  <td class="tac"><input type="text" maxlength="4" v-model="item.ij_count"
                      @change="jumunCountChange(item)" class="tac" style="width:25px;" /></td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="2">▶{{ item.ij_big_cate || '' }}<br/>▶{{ item.ij_small_cate || '' }}</td>

                  <td colspan="1" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>
                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>
                  <td colspan="1" v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>

                  <td colspan="3">
                    <p>공급단가: {{ addComma(item.ij_supply_price) || '' }}</p>
                    <p>VAT단가: {{ addComma(item.ij_vat_price) || '' }}</p>
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>


            <v-data-table v-else max-height="800px" fixed-header density="compact" :headers="headers" :items="jumun1"
              item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_big_cate || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_small_cate || '' }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;">{{ item.ij_name || '' }}
                    <span v-bind:style="{ marginLeft: '3px', color: getDdayDate(item.iu_Dday+1) >= itemIpgoDate ? 'red' : 'black', 'font-weight': getDdayDate(item.iu_Dday+1) >= itemIpgoDate ? '700' : '400' }">
                        ( D-{{ item.iu_Dday }} )
                    </span>
                  </td>
                  <td class="tac"><input type="text" maxlength="4" v-model="item.ij_count"
                      @change="jumunCountChange(item)" class="tac" style="width:50px;" /></td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_supply_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_vat_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="4"></td>

                  <td :colspan="headers.length - 7" class="tac" v-if="item.ij_photo !== null">
                   <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>
                  <td colspan="4"></td>
                </tr>
              </template>
            </v-data-table>
          </template>


          <div style="width:100%;">
            <table v-if="jumun1.length !== 0" class="jumunCtrlTb" style="display: inline-flex;">
              <tbody>
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumun1" @change="toggleSelectJumun1" label="전체선택" hide-details
                      class="fontNotoSans400" color="error" />
                  </td>
                  <td>
                    <button @click="jumunStatus(0)" class="jumunCancelBtn fontNotoSans700">
                      주문취소
                    </button>
                    <button @click="jumunStatus(2)" class="jumunOkBtn fontNotoSans700">
                      주문신청
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="isMobile" class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum1) }} 원
            </p>
            <h2 v-else class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum1) }} 원
            </h2>

          </div>
        </v-window-item>

        <v-window-item value="two">
          <div v-if="jumun2.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>
          <table v-if="jumun2.length !== 0" class="jumunCtrlTb">
            <tbody>
              <tr>
                <td>
                  <v-checkbox v-model="selectJumun2" @change="toggleSelectJumun2" label="전체선택" hide-details
                    class="fontNotoSans400" color="error" />
                </td>
                <td>
                  <button @click="jumunStatus(1)" class="jumunCancelBtn fontNotoSans700">
                    주문대기
                  </button>
                  <button v-if="isMobile" @click="jumunExcel(2)" class="jumunExcelBtn fontNotoSans700">
                    주문서엑셀
                    {{ jumun2[0].ij_jumun_date }}
                  </button>
                  <button v-else @click="jumunExcel(2)" class="jumunExcelBtn fontNotoSans700">
                    주문서
                    <v-icon>{{ mdiFileExcel }}</v-icon>{{ jumun2[0].ij_jumun_date }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <template v-if="jumun2.length !== 0">
            <v-data-table v-if="isMobile" max-height="900px" fixed-header density="compact" :headers="mHeaders"
              :items="jumun2" item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>

                      
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="2">▶{{ item.ij_big_cate || '' }}<br/>▶{{ item.ij_small_cate || '' }}</td>


                  <td colspan="1" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td colspan="1" v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>

                  <td colspan="3">
                    <p>공급단가: {{ addComma(item.ij_supply_price) || '' }}</p>
                    <p>VAT단가: {{ addComma(item.ij_vat_price) || '' }}</p>
                  </td>
<td></td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table v-else max-height="700px" fixed-header density="compact" :headers="headers" :items="jumun2"
              item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">

              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_big_cate || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_small_cate || '' }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;"
                    :class="{ 'highlight-duplicate': isDuplicate(item.ij_name) }">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_supply_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_vat_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="4"></td>

                  <td :colspan="headers.length - 7" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>
                  <td colspan="4"></td>
                </tr>
              </template>
            </v-data-table>
          </template>
          <div style="width:100%;">
            <table v-if="jumun2.length !== 0" class="jumunCtrlTb" style="display: inline-flex;">
              <tbody>
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumun2" @change="toggleSelectJumun2" label="전체선택" hide-details
                      class="fontNotoSans400" color="error" />
                  </td>
                  <td>
                    <button @click="jumunStatus(1)" class="jumunCancelBtn fontNotoSans700">
                      주문대기
                    </button>
                    <button v-if="isMobile" @click="jumunExcel(2)" class="jumunExcelBtn fontNotoSans700">
                    주문서엑셀
                      {{ jumun2[0].ij_jumun_date }}
                    </button>
                    <button v-else @click="jumunExcel(2)" class="jumunExcelBtn fontNotoSans700">
                      주문서
                      <v-icon>{{ mdiFileExcel }}</v-icon>{{ jumun2[0].ij_jumun_date }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="isMobile" class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum2) }} 원
            </p>
            <h2 v-else class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum2) }} 원
            </h2>
          </div>


        </v-window-item>

        <v-window-item value="three">
          <div v-if="jumun3.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>
          <template v-if="jumun3.length !== 0">

            <v-data-table v-if="isMobile" max-height="900px" fixed-header density="compact" :headers="mHeaders"
              :items="jumun3" item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>

                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="2">▶{{ item.ij_big_cate || '' }}<br/>▶{{ item.ij_small_cate || '' }}</td>


                  <td colspan="1" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td colspan="1" v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>

                  <td colspan="3">
                    <p>공급단가: {{ addComma(item.ij_supply_price) || '' }}</p>
                    <p>VAT단가: {{ addComma(item.ij_vat_price) || '' }}</p>
                  </td>
<td></td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table v-else max-height="700px" fixed-header density="compact" :headers="headers" :items="jumun3"
              item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">

              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_big_cate || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_small_cate || '' }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;"
                    :class="{ 'highlight-duplicate': isDuplicate(item.ij_name) }">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_supply_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_vat_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="4"></td>

                  <td :colspan="headers.length - 7" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>
                  <td colspan="4"></td>
                </tr>
              </template>
            </v-data-table>
          </template>
          <div style="width:100%;">
            <table v-if="jumun3.length !== 0" class="jumunCtrlTb" style="display: inline-flex;">
              <tbody>
                <tr>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="isMobile" class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum3) }} 원
            </p>
            <h2 v-else class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum3) }} 원
            </h2>
          </div>

        </v-window-item>


        <v-window-item value="four">
          <div v-if="jumun9.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>
          <table v-if="jumun9.length !== 0" class="jumunCtrlTb">
            <tbody>
              <tr>
                <td>
                  <v-checkbox v-model="selectJumun9" @change="toggleSelectJumun9" label="전체선택" hide-details
                    class="fontNotoSans400" color="error" />
                </td>
                <td>
                  <button @click="jumunStatus(1)" class="jumunCancelBtn fontNotoSans700">
                    주문대기로 복원
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <template v-if="jumun9.length !== 0">
            <v-data-table v-if="isMobile" max-height="900px" fixed-header density="compact" :headers="mHeaders"
              :items="jumun9" item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>

                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="2">▶{{ item.ij_big_cate || '' }}<br/>▶{{ item.ij_small_cate || '' }}</td>


                  <td colspan="1" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td colspan="1" v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>

                  <td colspan="3">
                    <p>공급단가: {{ addComma(item.ij_supply_price) || '' }}</p>
                    <p>VAT단가: {{ addComma(item.ij_vat_price) || '' }}</p>
                  </td>
<td></td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table v-else max-height="700px" fixed-header density="compact" :headers="headers" :items="jumun9"
              item-value="name" class="itemListTable fontNotoSans400" id="jumunTable">

              <template v-slot:item="{ item, index }">
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumunChange" :value="item" color="error" hide-details></v-checkbox>
                  </td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ index + 1 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_big_cate || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_small_cate || '' }}</td>
                  <td class="tal" @click="toggleRowDetails(index)" style="min-width:300px;"
                    :class="{ 'highlight-duplicate': isDuplicate(item.ij_name) }">{{ item.ij_name || '' }}( D-{{ item.iu_Dday }} )</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_count || 0 }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_supply_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_vat_price) || '' }}</td>
                  <td class="tar" @click="toggleRowDetails(index)">{{ addComma(item.ij_total_price) || 0 }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ item.ij_jaego || '' }}</td>
                  <td class="tac" @click="toggleRowDetails(index)">{{ ijStatusChanger(item.ij_status) || '' }}</td>

                </tr>
                <tr v-if="expandedRows.includes(index)">
                  <td colspan="4"></td>

                  <td :colspan="headers.length - 7" class="tac" v-if="item.ij_photo !== null">
                    <a v-if="item.ij_photo && item.ij_photo.includes('/_files')" :href="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="`http://www.xn--hu1bo4e.com/` + item.ij_photo || ''"></v-img>
                    </a>

                    <a  v-if="item.ij_photo && !item.ij_photo.includes('/_files')" :href="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''" target="_blank">
                      <v-img style="max-width:150px;" :src="currentUrl + `/public/static/itemPhoto/` + item.ij_photo || ''"></v-img>
                    </a>
                  </td>

                  <td v-else style="font-style: italic; color: blue;">⚠️이미지가 존재하지 않습니다.⚠️</td>
                  <td colspan="4"></td>
                </tr>
              </template>
            </v-data-table>
          </template>
          <div style="width:100%;">
            <table v-if="jumun9.length !== 0" class="jumunCtrlTb" style="display: inline-flex;">
              <tbody>
                <tr>
                  <td>
                    <v-checkbox v-model="selectJumun9" @change="toggleSelectJumun9" label="전체선택" hide-details
                      class="fontNotoSans400" color="error" />
                  </td>
                  <td>
                    <button @click="jumunStatus(1)" class="jumunCancelBtn fontNotoSans700">
                      주문대기로 복원
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="isMobile" class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum2) }} 원
            </p>
            <h2 v-else class="fontNotoSans700 mJumunTotalPrice" style="float:right; margin-top:14px;">
              총 합계 : {{ addComma(jumunPriceSum2) }} 원
            </h2>
          </div>


        </v-window-item>
      </v-window>
    </v-card-text>

  </v-card>
</template>

<script setup lang="ts">
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiFileExcel
} from '@mdi/js'
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const currentUrl = ref('');

import { useDisplay } from 'vuetify'
const { mobile } = useDisplay()
const isMobile = mobile.value

const isDuplicate = (ijName: any) => jumun2.value.filter((item: any) => item.ij_name === ijName).length > 1;

const tab = ref(null);
const expandedRows = ref([] as number[]);
const empId = computed(() => store.state.userInfoArr.emp_id);
const compId = computed(() => store.state.compInfoArr.comp_id);
const compName = computed(() => store.state.compInfoArr.comp_name);
const mainDate = computed(() => store.state.mainDate);

const toggleRowDetails = (index: number) => {
  if (expandedRows.value.includes(index)) {
    expandedRows.value = expandedRows.value.filter((i) => i !== index);
  } else {
    expandedRows.value.push(index);
  }
};



watch(compId, () => {
  jumunSelect();
});

const selectJumunChange = ref([] as any[]);
watch(selectJumunChange, () => {
  if (selectJumunChange.value.length !== jumun1.value.length) {
    selectJumun1.value = false
  }else{
    selectJumun1.value = true
  }
  if (selectJumunChange.value.length !== jumun2.value.length) {
    selectJumun2.value = false
  }else{
    selectJumun2.value = true
  }
  if (selectJumunChange.value.length !== jumun9.value.length) {
    selectJumun9.value = false
  }else{
    selectJumun9.value = true
  }
});
watch(tab, (newValue, oldValue) => {
  console.log(selectJumunChange.value,'selectJumunChange.value')
  // newValue는 변경된 값, oldValue는 이전 값입니다.
  if (newValue !== oldValue) {
    selectJumunChange.value = [];
    selectJumun1.value = false
    selectJumun2.value = false
    selectJumun9.value = false
  }
});

//1 선택 체크박스를 토글하는 메서드
const selectJumun1 = ref(false);
const toggleSelectJumun1 = () => {
  if (selectJumun1.value) {
    selectJumunChange.value = jumun1.value.slice();
  } else {
    selectJumunChange.value = [];
  }

};
// 2 선택 체크박스를 토글하는 메서드
const selectJumun2 = ref(false);
const toggleSelectJumun2 = () => {
  if (selectJumun2.value) {
    selectJumunChange.value = jumun2.value.slice();

  } else {
    selectJumunChange.value = [];
  }
};

const selectJumun9 = ref(false);
const toggleSelectJumun9 = () => {
  if (selectJumun9.value) {
    selectJumunChange.value = jumun9.value.slice();

  } else {
    selectJumunChange.value = [];
  }
};

function getDdayDate(Dday : any) {
    const now = new Date();
    const dday = new Date(now.getTime() + Dday * 24 * 60 * 60 * 1000); // 현재 날짜에 iu_Dday일을 더한 값 = iu_ipgo_date
    const year = dday.getFullYear();
    const month = String(dday.getMonth() + 1).padStart(2, '0'); 
    const day = String(dday.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
}

function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const itemIpgoDate = ref('')

const decrementDate = () => {
  const currentDate = new Date(itemIpgoDate.value);
  currentDate.setDate(currentDate.getDate() - 1);
  itemIpgoDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoDate.value);
  jumunSelect();
};

const incrementDate = () => {
  const currentDate = new Date(itemIpgoDate.value);
  currentDate.setDate(currentDate.getDate() + 1);
  itemIpgoDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoDate.value);
  jumunSelect();
};


watch(itemIpgoDate, (newValue: any) => {
  if (newValue) {
    const inputDate = new Date(newValue);
    itemIpgoDate.value = formatDate(inputDate);
    store.commit('setMainDate', itemIpgoDate.value);
  }
});

const changeMainDate = computed(() => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  const mainDateComputed = store.state.mainDate
  return mainDateComputed;
});

watch(changeMainDate, (newValue: any) => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  if (newValue) {
    itemIpgoDate.value = newValue;
    jumunSelect();
  }
});

//주문창에서 장바구니에서 옮겼을 경우 실시간 반영
const zzimListArrComputed = computed(() => store.state.zzimListArr);
watch(() => zzimListArrComputed.value, () => {
  jumunSelect();
});


const ijStatusChanger = (status : number) => {
  if (status === 1) {
    return '대기';
  } else if(status === 2) {
    return '신청'; 
  }else if(status === 3) {
    return '접수';
  }else if(status === 4) {
    return '입고';
  }else if(status === 9) {
    return '반려';
  }
};

function calcStringToNum(priceString:any) {
    const prices = priceString.match(/\d+\.\d+/g); // 문자열에서 가격 추출
    if (!prices) return 0; // 가격이 없을 경우 0 반환

    // 가격을 숫자로 변환하여 합산
    const total = prices.reduce((acc: any, price: any) => acc + parseFloat(price), 0);

    return total;
}


const jumunList = ref([] as any[]);
const jumun0 = ref([] as any[]);
const jumun1 = ref([] as any[]);
const jumun2 = ref([] as any[]);
const jumun3 = ref([] as any[]);
const jumun9 = ref([] as any[]);

const jumunPriceSum1 = ref(0)
const jumunPriceSum2 = ref(0)
const jumunPriceSum3 = ref(0)
const jumunPriceSum9 = ref(0)

const jumunSelect = async () => {
  try {

    const response = await axios.get(`/api/jumunSelect/${empId.value}/${compId.value}/${mainDate.value}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      selectJumunChange.value = [];
       // 전체선택 초기 값 설정
      jumunList.value = responseData;
      jumun0.value = responseData.filter(item => item.ij_status === 0);
      jumun1.value = responseData.filter(item => item.ij_status === 1);
      jumun2.value = responseData.filter(item => item.ij_status === 2);
      jumun3.value = responseData.filter(item => item.ij_status === 3);
      jumun9.value = responseData.filter(item => item.ij_status === 9);
      jumunPriceSum1.value = 0;
      for (const zzim of jumun1.value) {
        jumunPriceSum1.value += calcStringToNum(zzim.ij_total_price) || 0;
      }
      jumunPriceSum2.value = 0;
      for (const zzim of jumun2.value) {
        jumunPriceSum2.value += calcStringToNum(zzim.ij_total_price) || 0;
      }
      jumunPriceSum3.value = 0;
      for (const zzim of jumun3.value) {
        jumunPriceSum3.value += calcStringToNum(zzim.ij_total_price) || 0;
      }
      jumunPriceSum9.value = 0;
      for (const zzim of jumun9.value) {
        jumunPriceSum9.value += calcStringToNum(zzim.ij_total_price) || 0;
      }
      return responseData; // 가져온 데이터를 반환

    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};

const jumunCountChange = async (zzim: any) => {
  try {
    // zzim.ij_count가 정수인지 확인
    const parsedCount = parseInt(zzim.ij_count, 10);

    if (!isNaN(parsedCount) && Number.isInteger(parsedCount)) {
      // 사용자에게 확인 메시지 표시
      // 확인 버튼이 눌렸을 경우에만 삭제 로직 실행
      const response = await axios.post('/api/jumunCountChange', { form: zzim });
      const result = response.data;

      if (result.success === true) {
        await jumunSelect();
      } else {
        alert('에러가 발생하였습니다.');
      }
    } else {
      alert('숫자만 입력해주세요.');
    }
  } catch (error) {
    alert('오류');
    await jumunSelect();
    console.error(error);
  }
};

const jumunStatus = async (ijStatus:number) => {
  try {
    if(selectJumunChange.value.length === 0){
      alert('변경할 품목이 존재하지 않습니다.')
      return;
    }
      let confirm = ref();
    if(ijStatus === 1){
      confirm.value = window.confirm('주문대기 상태로 변경하시겠습니까?');
    }else if(ijStatus === 2){
      confirm.value = window.confirm('주문을 신청하시겠습니까?');
    }else if(ijStatus === 0){
      confirm.value = window.confirm('주문을 취소하시겠습니까?');
    }
    if (confirm.value) {
      console.log(selectJumunChange.value,'selectJumunChange.value')
      const response = await axios.post(`/api/jumunStatus/${ijStatus}`, { form: selectJumunChange.value });
      const result = response.data;
      if (result.success === true) {
        selectJumunChange.value = [];
        await jumunSelect();
      } else {
        alert('변경에 에러가 발생하였습니다.');
      }
    }else{
      return;
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};


const jumunExcel = async (status: number) => {
  try {
    const response = await axios.get(`/api/jumunExcel/${empId.value}/${compId.value}/${mainDate.value}/${status}`, {
      responseType: 'blob', // 설정 필요
    });
    // 엑셀 파일 다운로드
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `주문서_${compName.value}_${jumun2.value[0].ij_jumun_date}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};

function addComma(number:number) {
    // 숫자가 아니거나 null인 경우 그대로 반환
    if (number === null || isNaN(number)) return number;

    // 숫자를 문자열로 변환 후 컴마 추가
    var strNumber = number.toLocaleString('en-US', {maximumFractionDigits: 2});

    // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
    return strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const headers = ref([
  { title: '', key: '', align: 'center', sortable: false },
  { title: 'No.', key: 'index', sortable: false },
  { title: '대분류', key: 'ij_big_cate', align: 'center', sortable: true },
  { title: '소분류', key: 'ij_small_cate', align: 'center', sortable: true },
  { title: '품명', key: 'ij_name', align: 'center', sortable: true },
  { title: '수량', key: 'ij_count', align: 'center', sortable: true },
  { title: '공급단가', key: 'ij_supply_price', align: 'center', sortable: true },
  { title: '부가세', key: 'ij_vat_price', align: 'center', sortable: true },
  { title: '판매가', key: 'ij_total_price', align: 'center', sortable: true },
  { title: '재고', key: 'ij_jaego', align: 'center', sortable: true },
  { title: '상태', key: 'ij_status', align: 'center', sortable: true },


] as any[]);

const mHeaders = ref([
  { title: '', key: '', align: 'center', sortable: false },
  { title: 'No.', key: 'index', sortable: false },
  { title: '품명', key: 'ij_name', align: 'center', sortable: true },
  { title: '수량', key: 'ij_count', align: 'center', sortable: true },
  { title: '판매가', key: 'ij_total_price', align: 'center', sortable: true },
  { title: '재고', key: 'ij_jaego', align: 'center', sortable: true },
  { title: '상태', key: 'ij_status', align: 'center', sortable: true },

] as any[]);



onMounted(async () => {
  itemIpgoDate.value = store.state.mainDate;
  jumunSelect();
  currentUrl.value = window.location.origin; // 현재 호스트와 프로토콜을 가져옴
});
</script>

<style scoped>
.v-slide-group-item--active {
  background-color: #0f7545;
}
.v-tab span {
  color: #333;
}

.v-slide-group-item--active span {
  color: azure !important;
}

.v-tab {
  letter-spacing: -0.5px;
  width: 25%;
  border-bottom: 1px solid #0f7545;
  color: #0f7545;
}

.v-tab:hover {
  background-color: rgb(220, 241, 233);
}

.v-slide-group-item--active:hover {
  background-color: #0f7545 !important;
}

.v-tabs {
  border-bottom: 1px solid #0f7545;
  width: 100%;
}

button:hover {
  background-color: rgb(223, 237, 250);
}

button:active {
  background-color: rgb(191, 209, 224);
  cursor: pointer;
  /* 다양한 값 가능 (pointer, grab, text, etc.) */
  transform: translateY(0.5px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.v-icon:active {
  transform: translateY(1.2px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.highlight-duplicate {
  color: red;
  /* 예시: 노란색 배경 */
}
</style>