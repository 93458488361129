<template>
  <v-card class="mx-auto d-flex align-center justify-center" elevation="3" style="overflow-x: auto; width: 100%; box-sizing: border-box;
">
    <v-row>

      <v-col cols="12" style="padding-bottom:0;">
        <table class="jogunDateTable">
          <tbody>
            <tr>
              <td class="jumunJogunSelectTd">품목검색</td>
              <td class="jumunJogunSelectSecondTd">
                <div style="display: flex; align-items: center;">
                  <v-text-field style="min-width:100px;" v-model="search" color="#000"
                    label="검색" single-line clearable :append-inner-icon="mdiMagnify" flat hide-details="auto"
                    variant="outlined" density="compact">
                  </v-text-field>
                </div>
              </td>
              <td class="jumunJogunSelectTd">품목구분</td>
              <td class="">
                <div style="display: flex; flex-direction: column;">
                </div>
                <select v-model="selectedBigCate" @change="dynamicFilter" class=" dureJogunSelect">
                  <option value="all">품목분류(전체)</option>
                  <option value="가공식품">가공식품</option>
                  <option value="간식">간식</option>
                  <option value="간식거리">간식거리</option>
                  <option value="건어물">건어물</option>
                  <option value="늘푸른 김치">김치</option>
                  <option value="농산물">농산물</option>
                  <option value="수산물">수산물</option>
                  <option value="주방(생활용품)">주방(생활)용품</option>
                  <option value="축산물">축산물</option>
                </select>
                <select v-model="selectedJaego" @change="dynamicFilter" class=" dureJogunSelect">
                  <option value="all">재고(전체)</option>
                  <option value="O">재고: O</option>
                  <option value="X">재고: X</option>
                </select>
                <select v-model="selectedIsuse" @change="dynamicFilter" class=" dureJogunSelect">
                  <option value="all">사용유무(전체)</option>
                  <option value="O">사용: O</option>
                  <option value="X">사용: X</option>
                </select>
                <v-icon class="itemListFormChanger" @click="toggleView">
                  {{ isListForm ? mdiReorderHorizontal : mdiViewList }}
                </v-icon>
               
                <div style="display: flex; flex-direction: column;">

                </div>

              </td>
              <td class="tar jumunJogunSelectTd">상품등록</td>
              <td class="jumunRegistTd">
                <button v-if="isListForm" class="jumunOkBtn fontNotoSans400" @click="modalToggle(null)">
                  상품등록
                </button>
                <button v-if="!isListForm" class="jumunOkBtn fontNotoSans400" @click="sideToggle(null)">
                  상품등록
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col style="padding-top:0;">

        <div>

          <div v-if="crawlDureItem.length === 0" class="fontNotoSans400"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>

          <template v-if="isListForm">
            <v-data-table fixed-header :headers="headers" :items="filteredItems" item-value="name" :search="search"
              class="itemListTable fontNotoSans400" v-if="crawlDureItem.length !== 0">
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="tac" style="max-width:50px;">{{ index + 1 }}</td>
                  <td class="tac" style="min-width:50px; max-width:100px;">
                    <img v-if="!item.iu_photo || (item.iu_photo.includes('null')) || item.iu_photo === ''" src="@/assets/pictures/not_img.png"
                      style="width: 80%;" />
                    <v-img style="width: 80%;" v-else
                      :src="item.iu_photo && item.iu_photo.includes('/_files') ? `http://www.xn--hu1bo4e.com${item.iu_photo}` : item.imageUrl"></v-img>
                  </td>
                  <td class="tal" style="max-width:130px;">{{ item.iu_brand || '' }}</td>
                  <td class="tal" style="max-width:300px;"> {{ item.iu_name || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_jaego || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_Dday || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ item.iu_cate_big || '' }}</td>
                  <td class="tac" style="max-width:150px;">{{ item.iu_cate_small || '' }}</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_price) || 0 }} 원</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_vat_price) || 0 }} 원</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_purchase_price) || 0 }} 원</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_tax || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_isuse || '' }}</td>
                  <td class="tac" style="max-width:100px;">
                    <button class="mgtUpdateBtn" @click="modalToggle(item)">수정</button>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-card>


  <!-- 좌, 우 폼 -->
  <template v-if="!isListForm">
    <v-row>
      <v-col :class="{ 'okMgtItemListLeft': itemModal.iu_idx >= 0, 'noMgtItemListLeft': itemModal.iu_idx < 0 }">
        <v-card style=" max-height: calc(100vh - 150px);">
          <div>
            <v-data-table 
              fixed-header 
              :headers="headers2" 
              :items="filteredItems" 
              :items-per-page = "100"
              :items-per-page-options="[
                { value: 100, title: '100' },
                { value: 200, title: '200' },
                { value: 300, title: '300' },
                { value: 500, title: '500' },
                { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
              ]"
              item-value="name" 
              :search="search"
              height="auto" 
              width="auto" 
              scrollable 
              class="itemListTable fontNotoSans400"
              v-if="crawlDureItem.length !== 0"
              style=" max-height: 90vh; height: calc(100vh - 200px); overflow-y: hidden; position: relative;"
            >
              <template v-slot:item="{ item, index }">
                <tr class="iuToggleTr" @click="sideToggle(item)" :class="{ 'iuUpdateCard': selectedItem === item }">
                  <td class="tac" style="max-width:50px;">{{ index + 1 }}</td>
                  <td class="tal" style="max-width:300px;"> {{ item.iu_name || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_jaego || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_Dday || '' }}</td>

                  <td class="tac" style="max-width:110px;">{{ item.iu_cate_big || '' }}</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_price) || 0 }} 원</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_vat_price) || 0 }} 원</td>
                  <td class="tar" style="max-width:110px;">{{ addComma(item.iu_purchase_price) || 0 }} 원</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_tax || '' }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.iu_isuse || '' }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-col :class="{ 'okMgtItemListRight': itemModal.iu_idx >= 0, 'noMgtItemListRight': itemModal.iu_idx < 0 }">
        <v-card :class="{ 'iuRegistCard': itemModal.iu_idx === 0, 'iuUpdateCard': itemModal.iu_idx > 0 }">
          <h2 v-if="itemModal.iu_idx === 0" class="tac fontNotoSans700" style="margin-bottom: 10px;">상품등록</h2>
          <h2 v-if="itemModal.iu_idx > 0" class="tac fontNotoSans700" style="margin-bottom: 10px;">상품수정</h2>
          <v-table class="itemUpdateTable">
            <tbody>
              <tr>
                <td>상품명</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px; border-radius: 0; "
                      v-model="itemModal.iu_name" color="#000" clearable hide-details="auto" variant="outlined"
                      density="compact" maxlength="100">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>재고</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_jaego"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Dday</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_Dday"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="1">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>브랜드</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_brand"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      maxlength="30">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>대분류</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <select v-model="itemModal.iu_cate_big" @change="dynamicFilter">
                      <option value="">-- 선택 --</option>
                      <option value="가공식품">가공식품</option>
                      <option value="간식">간식</option>
                      <option value="간식거리">간식거리</option>
                      <option value="건어물">건어물</option>
                      <option value="늘푸른 김치">김치</option>
                      <option value="농산물">농산물</option>
                      <option value="수산물">수산물</option>
                      <option value="주방(생활용품)">주방(생활)용품</option>
                      <option value="축산물">축산물</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>소분류</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <select v-model="itemModal.iu_cate_small" @change="dynamicFilter">
                      <option value="">-- 선택 --</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'"
                        value="조미료/장/유지류">
                        조미료/장/유지류</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'"
                        value="냉장/냉동식품">
                        냉장/냉동식품</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="떡/빵/베이커리">떡/빵/베이커리</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="통조림">통조림</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'" value="영양곡">영양곡
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="라면/시리얼">라면/시리얼</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="분유/두유">분유/두유</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="커피차/음료/생수">커피차/음료/생수</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="소고기">소고기
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="우유/요구르트/음료/치즈">우유/요구르트/음료/치즈</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '늘푸른 김치'"
                        value="김치/반찬">
                        김치/반찬</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="과자/초코릿">과자/초코릿</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="채소">채소</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="계란/두부/콩나물">계란/두부/콩나물</option>
                      <option v-if="itemModal.iu_cate_big === '수산물' || itemModal.iu_cate_big === '건어물'" value="건어물">건어물
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="닭,오리">
                        닭,오리
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="돼지고기">
                        돼지고기
                      </option>
                      <option v-if="itemModal.iu_cate_big === '수산물'" value="생선">생선</option>
                      <option v-if="itemModal.iu_cate_big === '농산물' || itemModal.iu_cate_big === '수산물'" value="해산물/어패류">
                        해산물/어패류</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="과일">과일</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="쌀">쌀</option>

                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>판매가</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_price"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>매입가</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;"
                      v-model="itemModal.iu_purchase_price" color="#000" single-line clearable flat hide-details="auto"
                      variant="outlined" density="compact" :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>VAT</td>
                <td>
                  <v-radio-group v-model="itemModal.iu_tax" inline id="taxRadio" hide-details="auto">
                    <v-radio label="과세" value="과세"></v-radio>
                    <v-radio label="비과세" value="비과세"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>이미지</td>
                <td>
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <!-- 이미지 업로드 버튼 -->
                    <input type="file" accept="image/*" @change="handleFileUpload">
                    <!-- 이미지 미리보기 -->
                    <img :src="imageMiribogi ? imageMiribogi : itemModal.iu_photo"
                      style="max-width: 200px; max-height: 200px; margin-bottom: 5px;">

                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions>
            <v-btn class="fontNotoSans400" color="#424242" text="취소" variant="flat" @click="modalFalse"></v-btn>
            <v-spacer></v-spacer>
            <v-btn class="fontNotoSans400" v-if="itemModal.iu_idx === 0" color="#EADFB4" text="저장" variant="flat"
              @click="itemRegist"></v-btn>
            <v-btn class="fontNotoSans400" v-if="itemModal.iu_idx > 0" color="#0b4d2e" text="수정" variant="flat"
              @click="itemUpdate"></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>



  </template>

  <template>
    <div class="text-center pa-4">
      <v-dialog v-model="modal" persistent transition="dialog-right" :itemModal="itemModal" height="auto" width="auto"
        scrollable>
        <v-card :class="{ 'iuRegistCard': itemModal.iu_idx === 0, 'iuUpdateCard': itemModal.iu_idx > 0 }">
          <h2 v-if="itemModal.iu_idx === 0" class="tac fontNotoSans700" style="margin-bottom: 10px;">상품등록</h2>
          <h2 v-if="itemModal.iu_idx > 0" class="tac fontNotoSans700" style="margin-bottom: 10px;">상품수정</h2>
          <v-table class="itemUpdateTable">
            <tbody>
              <tr>
                <td>상품명</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px; border-radius: 0; "
                      v-model="itemModal.iu_name" color="#000" clearable hide-details="auto" variant="outlined"
                      density="compact" maxlength="100">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>재고</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_jaego"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Dday</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_Dday"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="1">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>브랜드</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_brand"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      maxlength="30">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>대분류</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <select v-model="itemModal.iu_cate_big" @change="dynamicFilter">
                      <option value="">-- 선택 --</option>
                      <option value="가공식품">가공식품</option>
                      <option value="간식">간식</option>
                      <option value="간식거리">간식거리</option>
                      <option value="건어물">건어물</option>
                      <option value="늘푸른 김치">김치</option>
                      <option value="농산물">농산물</option>
                      <option value="수산물">수산물</option>
                      <option value="주방(생활용품)">주방(생활)용품</option>
                      <option value="축산물">축산물</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>소분류</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <select v-model="itemModal.iu_cate_small" @change="dynamicFilter">
                      <option value="">-- 선택 --</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'"
                        value="조미료/장/유지류">조미료/장/유지류</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'"
                        value="냉장/냉동식품">냉장/냉동식품</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="떡/빵/베이커리">떡/빵/베이커리</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="통조림">통조림</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '농산물'" value="영양곡">영양곡
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="라면/시리얼">라면/시리얼</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="분유/두유">분유/두유</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="커피차/음료/생수">커피차/음료/생수</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="소고기">소고기
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="우유/요구르트/음료/치즈">우유/요구르트/음료/치즈</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '늘푸른 김치'"
                        value="김치/반찬">김치/반찬</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품'" value="과자/초코릿">과자/초코릿</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="채소">채소</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="계란/두부/콩나물">계란/두부/콩나물</option>
                      <option v-if="itemModal.iu_cate_big === '수산물' || itemModal.iu_cate_big === '건어물'" value="건어물">건어물
                      </option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="닭,오리">
                        닭,오리</option>
                      <option v-if="itemModal.iu_cate_big === '가공식품' || itemModal.iu_cate_big === '축산물'" value="돼지고기">
                        돼지고기</option>
                      <option v-if="itemModal.iu_cate_big === '수산물'" value="생선">생선</option>
                      <option v-if="itemModal.iu_cate_big === '농산물' || itemModal.iu_cate_big === '수산물'" value="해산물/어패류">
                        해산물/어패류</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="과일">과일</option>
                      <option v-if="itemModal.iu_cate_big === '농산물'" value="쌀">쌀</option>

                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>판매가</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;" v-model="itemModal.iu_price"
                      color="#000" single-line clearable flat hide-details="auto" variant="outlined" density="compact"
                      :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>매입가</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="min-width:100px;"
                      v-model="itemModal.iu_purchase_price" color="#000" single-line clearable flat hide-details="auto"
                      variant="outlined" density="compact" :rules="jungsuRule" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>VAT</td>
                <td>
                  <v-radio-group v-model="itemModal.iu_tax" inline id="taxRadio" hide-details="auto">
                    <v-radio label="과세" value="과세"></v-radio>
                    <v-radio label="비과세" value="비과세"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>이미지</td>
                <td>
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <!-- 이미지 업로드 버튼 -->
                    <input type="file" accept="image/*" @change="handleFileUpload">
                    <!-- 이미지 미리보기 -->
                    <img :src="imageMiribogi ? imageMiribogi : itemModal.iu_photo"
                      style="max-width: 200px; max-height: 200px; margin-bottom: 5px;">
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions>
            <v-btn class="fontNotoSans400" color="#424242" text="취소" variant="flat" @click="modalFalse"></v-btn>
            <v-spacer />
            <v-btn class="fontNotoSans400" v-if="itemModal.iu_idx === 0" color="#EADFB4" text="저장" variant="flat"
              @click="itemRegist"></v-btn>
            <v-btn class="fontNotoSans400" v-else-if="itemModal.iu_idx > 0" color="#0b4d2e" text="수정" variant="flat"
              @click="itemUpdate"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, reactive } from 'vue';
import axios from 'axios';
import {
  mdiMagnify,
  mdiViewList,
  mdiReorderHorizontal
} from '@mdi/js'
import { useStore } from 'vuex';
import {
  jungsuRule,
}
  from '@/composables/rules';
import { useDisplay } from 'vuetify'
const { mobile } = useDisplay()
const isMobile = mobile.value
const isListForm = ref(isMobile); // 리스트 형식 여부
const toggleView = () => {
  isListForm.value = !isListForm.value;
};
const store = useStore();
const crawlDureItem = ref([] as any[]);
const crawlDureItem2 = ref([] as any[]);
const selectedIsuse = ref('all');
const selectedBigCate = ref('all');
const selectedJaego = ref('all');

const search = ref(store.state.searchItem.searchItem || '');
const account = ref({
  emp_id: null,
  emp_name: '',
  emp_role: '',
});

const headers = ref([
  { title: 'No.', key: 'index', align: 'center' },
  { title: '이미지', key: 'iu_photo', align: 'center', sortable: false },
  { title: '업 체', key: 'iu_brand', align: 'start', sortable: true },
  { title: '상 품', key: 'iu_name', align: 'center', sortable: true },
  { title: '재 고', key: 'iu_jaego', align: 'center', sortable: true },
  { title: 'Dday', key: 'iu_Dday', align: 'center', sortable: true },
  { title: '대분류', key: 'iu_cate_big', align: 'center', sortable: true },
  { title: '소분류', key: 'iu_cate_small', align: 'center', sortable: true }, 
  { title: '판매가', key: 'iu_price', align: 'center', sortable: true },
  { title: 'VAT', key: 'iu_vat_price', align: 'center', sortable: true },
  { title: '매입가', key: 'iu_purchase_price', align: 'center', sortable: true },
  { title: '세금', key: 'iu_tax', align: 'center', sortable: true },
  { title: '사용', key: 'iu_isuse', align: 'center', sortable: true },
  { title: '수정', key: '', align: 'center', sortable: true },
] as any[]);

const headers2 = ref([
  { title: 'No.', key: 'index', align: 'center' },
  { title: '상 품', key: 'iu_name', align: 'center', sortable: true },
  { title: '재 고', key: 'iu_jaego', align: 'center', sortable: true },
  { title: 'Dday', key: 'iu_Dday', align: 'center', sortable: true },
  { title: '대분류', key: 'iu_cate_big', align: 'center', sortable: true },
  { title: '판매가', key: 'iu_price', align: 'center', sortable: true },
  { title: 'VAT', key: 'iu_vat_price', align: 'center', sortable: true },
  { title: '매입가', key: 'iu_purchase_price', align: 'center', sortable: true },
  { title: '세금', key: 'iu_tax', align: 'center', sortable: true },
  { title: '사용', key: 'iu_isuse', align: 'center', sortable: true },
] as any[]);


function addComma(number: any) {
  if (isNaN(number)) return number; // 숫자가 아닌 경우 그대로 반환
  return number.toLocaleString(); // 숫자인 경우 컴마를 추가한 문자열 반환
}

const itemModal = reactive({
  iu_name: '',
  iu_brand: '',
  iu_jaego: '',
  iu_Dday: '',
  iu_cate_big: '',
  iu_cate_small: '',
  iu_price: 0,
  iu_purchase_price: 0,
  iu_photo: '',
  iu_tax: '',
  iu_idx: -1, //초기값 -1로써, 초기페이지로딩 시 -1 갖기. 그 후엔 바뀜
  currentUrl: ''
});
const modal = ref(false);
const modalToggle = (item: any) => {
  if (item === null) {
    itemModal.iu_name = '';
    itemModal.iu_brand = '';
    itemModal.iu_jaego = '';
    itemModal.iu_Dday = '';
    itemModal.iu_cate_big = '';
    itemModal.iu_cate_small = '';
    itemModal.iu_price = 0;
    itemModal.iu_purchase_price = 0;
    itemModal.iu_tax = '과세';
    itemModal.iu_idx = 0;
    itemModal.iu_photo = '';
  } else {
    itemModal.iu_name = item.iu_name;
    itemModal.iu_brand = item.iu_brand;
    itemModal.iu_jaego = item.iu_jaego;
    itemModal.iu_Dday = item.iu_Dday;
    itemModal.iu_cate_big = item.iu_cate_big;
    itemModal.iu_cate_small = item.iu_cate_small;
    itemModal.iu_price = item.iu_price;
    itemModal.iu_purchase_price = item.iu_purchase_price;
    itemModal.iu_tax = item.iu_tax;
    itemModal.iu_idx = item.iu_idx;
    // item.iu_photo가 null이 아닌 경우에만 처리
    if (item.iu_photo && item.iu_photo.includes('/_files')) {
      itemModal.iu_photo = `http://www.xn--hu1bo4e.com${item.iu_photo}`;
    } else {
      itemModal.iu_photo = item.imageUrl || '';
    }
  }

  modal.value = !modal.value;
};
const modalFalse = () => {
  // 모달 상태에 저장된 이미지 초기화
  imageMiribogi.value = '';
  imageFile.value = '';
  modal.value = false;
  itemModal.iu_name = '';
  itemModal.iu_brand = '';
  itemModal.iu_jaego = '';
  itemModal.iu_Dday = '';
  itemModal.iu_cate_big = '';
  itemModal.iu_cate_small = '';
  itemModal.iu_price = 0;
  itemModal.iu_purchase_price = 0;
  itemModal.iu_photo = '';
  itemModal.iu_tax = '';
  itemModal.iu_idx = -1;
}

const selectedItem = ref('');
// 행을 클릭했을 때 호출되는 함수
const sideToggle = (item: any) => {
  selectedItem.value = ''
  console.log(item, 'item')

  if (item === null) {
    itemModal.iu_name = '';
    itemModal.iu_brand = '';
    itemModal.iu_jaego = '';
    itemModal.iu_Dday = '';
    itemModal.iu_cate_big = '';
    itemModal.iu_cate_small = '';
    itemModal.iu_price = 0;
    itemModal.iu_purchase_price = 0;
    itemModal.iu_tax = '과세';
    itemModal.iu_idx = 0;
    itemModal.iu_photo = '';
  } else {
    selectedItem.value = item;
    itemModal.iu_name = item.iu_name;
    itemModal.iu_brand = item.iu_brand;
    itemModal.iu_jaego = item.iu_jaego;
    itemModal.iu_Dday = item.iu_Dday;
    itemModal.iu_cate_big = item.iu_cate_big;
    itemModal.iu_cate_small = item.iu_cate_small;
    itemModal.iu_price = item.iu_price;
    itemModal.iu_purchase_price = item.iu_purchase_price;
    itemModal.iu_tax = item.iu_tax;
    itemModal.iu_idx = item.iu_idx;
    // item.iu_photo가 null이 아닌 경우에만 처리
    if (item.iu_photo && item.iu_photo.includes('/_files')) {
      itemModal.iu_photo = `http://www.xn--hu1bo4e.com${item.iu_photo}`;
    } else {
      itemModal.iu_photo = item.imageUrl || '';
    }
  }
};



const imageMiribogi = ref();
const imageFile = ref('');
const fileExtension = ref();
const handleFileUpload = (event: any) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = () => {
    imageMiribogi.value = reader.result;
  };
  // 파일을 읽어오기 시작
  reader.readAsDataURL(file);
  imageFile.value = file;
  fileExtension.value = getFileExtension(file.name);
};
function getFileExtension(filename: any) {
  return filename.split('.').pop();
}


const itemUpdate = async () => {
  try {
    if(itemModal.iu_photo.includes('/_files')){
        itemModal.iu_photo = itemModal.iu_photo.replace("http://www.xn--hu1bo4e.com", "");
      }else if(itemModal.iu_photo === null || itemModal.iu_photo === '' || itemModal.iu_photo === undefined){
        itemModal.iu_photo = '';
      }else{
        itemModal.iu_photo = `${itemModal.iu_idx}_${itemModal.iu_name.replace(/[^\wㄱ-힣\s]/gi, '')}.${fileExtension.value}`;
      }

    const confirmed = window.confirm('정말 수정하시겠습니까?');

    if (confirmed) {
   

      const formData = new FormData();
      formData.append('image', imageFile.value);
      const response = await axios.post('/api/itemUpdate', formData, {
        params: itemModal,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const result = response.data;

      if (result.success === true) {
        await mgtItemUpload();
        modalFalse();
        itemModal.iu_name = '';
        itemModal.iu_brand = '';
        itemModal.iu_jaego = '';
        itemModal.iu_Dday = '';
        itemModal.iu_cate_big = '';
        itemModal.iu_cate_small = '';
        itemModal.iu_price = 0;
        itemModal.iu_purchase_price = 0;
        itemModal.iu_photo = '';
        itemModal.iu_tax = '';
        itemModal.iu_idx = 0;
        alert('수정이 완료되었습니다.')

      } else {
        alert('수정 중 에러가 발생하였습니다.');
      }
    } else {
      return;
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};




const itemRegist = async () => {
  try {

    const checkAndLog = (rule: any, value: any, ruleName: any) => {
      const ruleResult = rule(value);
      if (typeof ruleResult === 'string') {
        alert(`${ruleName}를 확인하세요.`);
        return true;
      } else {
        return false;
      }
    };
    const isDdayValid = jungsuRule.some(rule => checkAndLog(rule, itemModal.iu_Dday, 'Dday'));
    if (isDdayValid) return;
    const isJaegoValid = jungsuRule.some(rule => checkAndLog(rule, itemModal.iu_jaego, '재고'));
    if (isJaegoValid) return;
    const isPriceValid = jungsuRule.some(rule => checkAndLog(rule, itemModal.iu_price, '판매가'));
    if (isPriceValid) return;
    const isPurchaseValid = jungsuRule.some(rule => checkAndLog(rule, itemModal.iu_purchase_price, '매입가'));
    if (isPurchaseValid) return;

    itemModal.iu_photo = `${itemModal.iu_idx}_${itemModal.iu_name.replace(/[^\wㄱ-힣\s]/gi, '')}.${fileExtension.value}`;

    const formData = new FormData();
    formData.append('image', imageFile.value);

    const response = await axios.post('/api/itemRegist', formData, {
      params: itemModal,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const result = response.data;
    if (result.success === true) {
      alert(`[ ${itemModal.iu_name} ] 상품 등록이 완료되었습니다.`)
      await mgtItemUpload();
      modalFalse();
      itemModal.iu_name = '';
      itemModal.iu_brand = '';
      itemModal.iu_jaego = '';
      itemModal.iu_Dday = '';
      itemModal.iu_cate_big = '';
      itemModal.iu_cate_small = '';
      itemModal.iu_price = 0;
      itemModal.iu_purchase_price = 0;
      itemModal.iu_photo = '';
      itemModal.iu_tax = '';
      itemModal.iu_idx = 0;
    } else if (result.success === false) {
      alert('중복된 상품입니다.');
    } else {
      alert('상품등록 에러발생');
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};



const mgtItemUpload = async () => {
  try {
    let currentUrl = window.location.origin; // 현재 호스트와 프로토콜을 가져옴
    if (currentUrl === 'http://hamwoori.co.kr') {
      currentUrl = 'http://61.253.115.156:19919'
    } else {
      currentUrl = 'http://49.247.8.206:3001'
    }
    // axios.get 함수에서 currentUrl을 라우트 매개변수로 보냄
    const response = await axios.get(`/api/mgtItemUpload/${encodeURIComponent(currentUrl)}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      crawlDureItem.value = responseData;
      crawlDureItem2.value = responseData;
      console.log(crawlDureItem.value, 'crawlDureItem.value');
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};


const dynamicFilter = () => {
  let filteredData = crawlDureItem2.value;

  // '재고'가 'O'인 경우
  if (selectedJaego.value === 'O') {
    filteredData = filteredData.filter(item => parseInt(item.iu_jaego) >= 1);
  }
  // '재고'가 'X'인 경우
  else if (selectedJaego.value === 'X') {
    filteredData = filteredData.filter(item => parseInt(item.iu_jaego) < 1);
  }

  const filters: Record<string, string> = {
    iu_isuse: selectedIsuse.value,
    iu_cate_big: selectedBigCate.value,
    // 다른 필터를 추가할 수 있음
  };

  // filters 객체를 순회하면서 필터를 적용
  for (const key in filters) {
    const value = filters[key];
    if (value !== 'all') {
      filteredData = filteredData.filter(item => {
        if (key === 'iu_jaego' && selectedJaego.value === 'O') {
          return parseInt(item[key]) >= 1;
        } else if (key === 'iu_jaego' && selectedJaego.value === 'X') {
          return parseInt(item[key]) < 1;
        } else {
          return item[key] === value;
        }
      });
    }
  }
  crawlDureItem.value = filteredData;
};

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return crawlDureItem.value;
  }
  return crawlDureItem.value.filter(item =>
    (item.iu_brand && item.iu_brand.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_name && item.iu_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_cate_big && item.iu_cate_big.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_cate_small && item.iu_cate_small.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_isuse && item.iu_isuse.toLowerCase().includes(search.value.toLowerCase()))
  );
});

onMounted(async () => {
  mgtItemUpload();
  account.value = store.state.userInfoArr;
});
</script>

<style scoped>
.v-icon:active {
  transform: translateY(1.2px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.v-text-field {
  padding: 0;
}

.iuToggleTr {
  overflow-y: auto;
  cursor: pointer;
}

.iuToggleTr:active {
  transform: translateY(1.2px);
  background-color: #d1d1d1;
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.okMgtItemListLeft {
  max-width: 68%;
  min-width: 68%;
  padding-right: 3px;
  padding-bottom: 0;
  margin-top: 5px;
}

.okMgtItemListRight {
  width: 30%;
  padding-bottom: 0;
  margin-top: 5px;
}

.noMgtItemListLeft {
  max-width: 100%;
  min-width: 100%;
  margin-top: 5px;
}


.noMgtItemListRight {
  display: none;
}

.iuUpdateCard {
  background-color: #dbe7e6;
}
</style>