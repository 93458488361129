<template>
  <v-card>
    <v-row>
      <v-col cols="12">
        <table class="jogunDateTable">
          <tbody>
            <tr>
              <td class="jumunJogunSelectTd">입고일</td>
              <td class="jumunJogunSelectSecondTd">
                <div style="display: flex; align-items: center; justify-items: center;">
                <v-icon @click="decrementStartDate">{{ mdiChevronLeft }}</v-icon>
                <v-text-field 
                type="date"
                 variant="outlined" 
                 v-model="itemIpgoStartDate" 
                 density="compact"
                  hide-details 
                  shaped 
                 />
                <v-icon @click="incrementStartDate">{{ mdiChevronRight }}</v-icon>
                ~
              </div>
              </td>
              <td class="jumunJogunSelectSecondTd">
                <div style="display: flex; align-items: center; justify-items: center;">
                <v-icon @click="decrementEndDate">{{ mdiChevronLeft }}</v-icon>
                <v-text-field 
                type="date"
                 variant="outlined" 
                 v-model="itemIpgoEndDate" 
                 density="compact"
                  hide-details 
                  shaped 
                 />
                <v-icon @click="incrementEndDate">{{ mdiChevronRight }}</v-icon>
              </div>
              </td>
              <td></td>
              <td class="tar">
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <div v-if="mgtJumunList.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>
            <table v-if="mgtJumunList.length !== 0" class="jumunCtrlTb">
            <tbody>
              <tr>
                <td>
                 

                </td>
              </tr>
            </tbody>
          </table>
        <v-card style=" max-height: calc(100vh - 200px);">

          <v-data-table
          max-height="700px" 
          fixed-header 
          scrollable
          :items-per-page = "30"
          :items-per-page-options="[
              { value: 30, title: '30' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: 200, title: '200' },
              { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
          ]"
          density="compact" 
          :headers="headers"
          :items="mgtJumunList"
          item-value="name" 
          class="itemListTable fontNotoSans400" 
          id="jumunTable" 
          v-if="mgtJumunList.length !== 0"
          style=" max-height: 90vh; height: calc(100vh - 250px); overflow-y: hidden; position: relative;"
          >

            <template v-slot:item="{ item, index }">
              <tr>
                <td class="tac">{{ index + 1 }}</td>
                <td class="tac">{{ item.comp_name || '' }}</td>
                <td class="tac">{{ item.emp_name || '' }}</td>
                <td class="tac">{{ item.ij_jumun_date || '' }}</td>
                <td class="tac">{{ item.ij_ipgo_date || 0 }}</td>
                <td class="tar">{{ addComma(item.total_price) || 0 }} 원</td>
                <td class="tar">{{ addComma(item.total_purchase_price) || 0 }} 원</td>
                <td class="tac">{{ ijStatusChanger(item.ij_status) || '' }}</td>
                <td class="tac">
                  <button class="mgtUpdateBtn" @click="modalToggle(item)">상세내역</button>
                </td>

              </tr>
            </template>
      
          </v-data-table>
      </v-card>
    <v-row>
    </v-row>
  </v-card>
<template>
  <div class="text-center pa-4">
    <v-dialog v-model="modal" persistent transition="dialog-right" 
      height="auto" width="900px" scrollable>
      <v-card>
        <v-data-table fixed-header density="compact" :headers="headers2" :items="jumunList"
            item-value="name" class="itemListTable fontNotoSans400" id="jumunTable" v-if="jumunList.length !== 0">

            <template v-slot:item="{ item, index }">
              <tr>
                <td class="tac">
                  {{ index + 1 }}
                </td>
                <td class="tac">
                  {{ item.ij_big_cate || '' }}
                </td>
                <td class="tac">
                  {{ item.ij_small_cate || '' }}
                </td>
                <td class="tal">
                  {{ item.ij_name || '' }}
                </td>
                <td class="tac">
                  <input class="tar" type="number" maxlength="4" v-model="item.ij_count" style="width:45px; border-bottom: 1px solid #454545;"/>
                </td>
                <td class="tar">
                  <input class="tar" type="number" maxlength="4" v-model="item.ij_supply_price" style="width:70px;  border-bottom: 1px solid #454545;"/>
                </td>
                <td class="tar">{{ calculateVatPrice(item) || 0 }}</td>
                <td class="tar">{{ calculateTotalPrice(item) || 0 }}</td>
                <td class="tac">
                  <input class="tar" type="number" maxlength="4" v-model="item.ij_jaego" style="width:45px;"/>
                </td>
              </tr>
            </template>
          </v-data-table>
        <v-card-actions>
            <v-btn color="#424242" text="취소" variant="flat" @click="modalFalse"></v-btn>
            <v-spacer></v-spacer>
            <select v-model="ijStatusSelect" class="dureJogunSelect fontNotoSans400" label="">
              <option class="dureJogunSelectOption" value="2" label="- 선택 -"></option>
              <option class="dureJogunSelectOption" value="3" label="주문접수"></option>
              <option class="dureJogunSelectOption" value="4" label="입고완료"></option>
              <option class="dureJogunSelectOption" value="9" label="주문반려"></option>
            </select>
            <v-btn color="#0f7545" text="수정" variant="flat" @click="jumunStatus()"></v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

</template>

<script setup lang="ts">
import {
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import axios from 'axios';
import { onMounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const modal = ref(false);
const empId = ref();
const compId = ref();
const ipgoDate = ref();
const ijStatusSelect = ref('2');
const ijStatusSelectBefore = ref('2');

const modalToggle = (item: any) => {
  empId.value = item.emp_id;
  compId.value = item.comp_id;
  ipgoDate.value = item.ij_ipgo_date;
  ijStatusSelect.value = item.ij_status
  ijStatusSelectBefore.value = item.ij_status

  modal.value = !modal.value;
  mgtJumunDetailSelect();
};
const modalFalse = () => {
  modal.value = false;
}

const headers = ref([
  { title: 'No.', key: 'index', sortable: false },
  { title: '고객사', key: 'comp_name', align: 'center', sortable: true },
  { title: '주문자', key: 'emp_name', align: 'center', sortable: true },
  { title: '주문일', key: 'ij_jumun_date', align: 'center', sortable: true },
  { title: '입고일', key: 'ij_ipgo_date', align: 'center', sortable: true },
  { title: '주문합계', key: 'total_price', align: 'center', sortable: true },
  { title: '매입합계', key: 'total_purchase_price', align: 'center', sortable: true },
  { title: '상태', key: 'ij_status', align: 'center', sortable: true },
  { title: '주문상세', key: '', align: 'center', sortable: true },
] as any[]);

const headers2 = ref([
  { title: 'No.', key: 'index', sortable: false },
  { title: '대분류', key: 'ij_big_cate', align: 'center', sortable: true },
  { title: '소분류', key: 'ij_small_cate', align: 'center', sortable: true },
  { title: '품명', key: 'ij_name', align: 'center', sortable: true },
  { title: '수량', key: 'ij_count', align: 'center', sortable: true },
  { title: '공급단가', key: 'ij_supply_price', align: 'center', sortable: true },
  { title: '부가세', key: 'ij_vat_price', align: 'center', sortable: true },
  { title: '판매가', key: 'ij_total_price', align: 'center', sortable: true },
  { title: '재고', key: 'ij_jaego', align: 'center', sortable: true },
] as any[]);

  const calculateVatPrice = ref((item:any) => {
    if(item.ij_tax === '과세'){
      return addComma(item.ij_supply_price * 0.1);
    }else{
      return '0';
    }
});

const calculateTotalPrice = ref((item:any) => {
  if(item.ij_tax === '과세'){
    return addComma(item.ij_count * item.ij_supply_price+ item.ij_count * item.ij_vat_price);
  }else{
    return addComma(item.ij_count * item.ij_supply_price);
  }
});

function addComma(number:number) {
    // 숫자가 아니거나 null인 경우 그대로 반환
    if (number === null || isNaN(number)) return number;

    // 숫자를 문자열로 변환 후 컴마 추가
    var strNumber = number.toLocaleString('en-US', {maximumFractionDigits: 2});

    // 소수점 이하가 모두 0이 아닌 경우 처리
    var decimalIndex = strNumber.indexOf('.');
    if (decimalIndex !== -1) {
        var decimalPart = strNumber.substr(decimalIndex + 1);
        if (decimalPart !== '' && !/^0+$/.test(decimalPart)) {
            // 소수점 이하가 모두 0이 아닌 경우, 소수점 이하를 그대로 유지
            return strNumber;
        }
    }

    // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
    return strNumber.split('.')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
//1 선택 체크박스를 토글하는 메서드

function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
const mgtJumunList = ref([] as any[])
const jumunList = ref([] as any[])

const itemIpgoStartDate = ref('')

const decrementStartDate = () => {
  const currentDate = new Date(itemIpgoStartDate.value);
  currentDate.setDate(currentDate.getDate() - 1);
  itemIpgoStartDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoStartDate.value);
  mgtJumunSelect();
};

const incrementStartDate = () => {
  const currentDate = new Date(itemIpgoStartDate.value);
  currentDate.setDate(currentDate.getDate() + 1);
  itemIpgoStartDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoStartDate.value);
  if(itemIpgoStartDate.value >= itemIpgoEndDate.value){
      itemIpgoEndDate.value = itemIpgoStartDate.value
    }
  mgtJumunSelect();
};

const itemIpgoEndDate = ref('')

const decrementEndDate = () => {
  const currentDate = new Date(itemIpgoEndDate.value);
  currentDate.setDate(currentDate.getDate() - 1);
  itemIpgoEndDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoEndDate.value);
  if(itemIpgoEndDate.value <= itemIpgoStartDate.value){
    itemIpgoStartDate.value = itemIpgoEndDate.value
  }
  mgtJumunSelect();
};

const incrementEndDate = () => {
  const currentDate = new Date(itemIpgoEndDate.value);
  currentDate.setDate(currentDate.getDate() + 1);
  itemIpgoEndDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoEndDate.value);
  mgtJumunSelect();
};

const startDateWatcher = computed(() => itemIpgoStartDate.value);
const endDateWatcher = computed(() => itemIpgoEndDate.value);

watch(startDateWatcher, () => {
  mgtJumunSelect();
});

watch(endDateWatcher, () => {
  mgtJumunSelect();
});


const mgtJumunSelect = async () => {
  try {

    const response = await axios.get(`/api/mgtJumunSelect/${itemIpgoStartDate.value}/${itemIpgoEndDate.value}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      mgtJumunList.value = responseData;
      console.log(mgtJumunList.value,'mgtJumunList.value')
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};


const mgtJumunDetailSelect = async () => {
  try {
    console.log(ijStatusSelect.value,'ijStatusSelect.value')
    const response = await axios.get(`/api/mgtJumunDetailSelect/${empId.value}/${compId.value}/${ipgoDate.value}/${ijStatusSelect.value}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      jumunList.value = responseData.filter(item => item.ij_status === 2 || item.ij_status === 3 || item.ij_status === 4 || item.ij_status === 9);
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};

const jumunStatus = async () => {
  if(ijStatusSelect.value === '2'){
        return;
  }
  try {
    for (let i = 0; i < jumunList.value.length; i++) {
      jumunList.value[i].ij_supply_price =parseFloat(jumunList.value[i].ij_supply_price); // int로 변경

      if (jumunList.value[i].ij_count.toString().length > 4) {
        alert('수량은 최대 4자리여야 합니다.');
        return;
      }
      if (jumunList.value[i].ij_supply_price.toString().length > 10) {
        alert('공급단가는 최대 10자리여야 합니다.');
        return;
      }
      if (jumunList.value[i].ij_jaego.toString().length > 4) {
        alert('재고는 최대 4자리여야 합니다.');
        return;
      }

    }

    let confirmed = ref() as any;
    confirmed.value = window.confirm('변경 사항을 저장하시겠습니까?');
    if (confirmed.value) {

      const response = await axios.post(`/api/jumunStatus/${ijStatusSelect.value}`, { form: jumunList.value });
      const result = response.data;
      if (result.success === true) {
        await mgtJumunSelect();
          alert('저장이 완료되었습니다.')
      } else {
        alert('저장에 에러가 발생하였습니다.');
      }
    } else {
      return;
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
  modalFalse();
};


const ijStatusChanger = (status : number) => {
  if (status === 2) {
    return '주문신청';
  } else if(status === 3) {
    return '주문접수'; 
  }else if(status === 4) {
    return '입고완료';
  }else if(status === 9) {
    return '주문반려';
  }
};



onMounted(async () => {
  itemIpgoStartDate.value = formatDate(new Date());
  itemIpgoEndDate.value = formatDate(new Date());
  mgtJumunSelect();
});
</script>

<style scoped>
.v-slide-group-item--active {
  border-bottom: none !important;
  background-color: #0f7545;

}

.v-slide-group-item--active span {
  color: azure !important;
}


.v-slide-group-item--active:hover {
  background-color: #0f7545 !important;
}


button:hover {
  background-color: rgb(223, 237, 250);
}

button:active {
  background-color: rgb(191, 209, 224);
  cursor: pointer;
  /* 다양한 값 가능 (pointer, grab, text, etc.) */
  transform: translateY(0.5px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.v-icon:active {
  transform: translateY(1.2px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}
.highlight-duplicate {
  color: red; /* 예시: 노란색 배경 */
}
</style>