<template>
  <v-sheet class="indexSheet" style = "width:100%; display: flex;">
    <v-card v-if="!isMobile" style="width:100%; text-align: center;">
      <v-carousel style="margin-top: 10px; height: 100%;">
        <v-carousel-item >
          <img style="min-height: 100%;" src="@/assets/pictures/test1.jpg" />
        </v-carousel-item>

        <v-carousel-item>
          <img style="min-height: 100%;" src="@/assets/pictures/test2.jpg" />
        </v-carousel-item>

        <v-carousel-item>
          <img style="min-height: 100%;" src="@/assets/pictures/test3.jpg" />
        </v-carousel-item>
 
      </v-carousel>
    </v-card >

    <v-card class="mIndexUserInfoCard" v-else style="width:100%;  max-width: 412px; text-align: center; margin:auto;">
      <v-card-item>
      <v-card-title class="fontNotoSans700">
        {{ empName }} 님
      </v-card-title>
      <v-card-subtitle style="opacity: 1; border-bottom: 1px solid #c1c1c1;">
        {{ empRole }}
      </v-card-subtitle>
    </v-card-item>

    <v-card-text>
      <table class="indexUserInfoTb">
      <tbody>
        <tr class="indexUserInfoTr">
          <td>사업장 이름 </td>
          <td>{{ compName }}</td>
        </tr>
        <tr class="indexUserInfoTr">
          <td>사업장 구분 </td>
          <td>{{ compGubun }}</td>
        </tr>
        <tr class="indexUserInfoTr">
          <td>사업장 대표 </td>
          <td>{{ compBoss }} / {{ compBossCp }}</td>
        </tr>
      </tbody>
    </table>    
    <v-tabs
      v-model="tab"
      bg-color="#fff"
      class="indexTabs"
    >
      <v-tab value="one" class="indexTab1 fontNotoSans700" style="color: #fff;">주문</v-tab>
      <v-tab value="two" class="indexTab2 fontNotoSans700" style="color: #fff;">식단</v-tab>
    </v-tabs>
    <v-window v-model="tab">
        <v-window-item value="one">

          <div class="calendar"> 
            
          <div style="display: flex; align-items: center; justify-content: center;">
            <v-icon @click="previousMonth" size="26">{{ mdiChevronLeft }}</v-icon>
            <h2 style="height: 24px;">{{ currentMonth }}</h2>
            <v-icon @click="nextMonth" size="26">{{ mdiChevronRight }}</v-icon>
          </div>
          
          <table class="indexDateTable">
            <thead>
              <tr>
                <th v-for="day in daysOfWeek" :key="day" :class="{ redText: day === '일', blueText: day === '토' }">{{ day }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="week in calendar" :key="week[0].date.toISOString()">
                <td v-for="day in week" :key="day.date.toISOString()">
                  <v-avatar size="35" @click="indexDateClick(addDays(day.date, 0))" class="dayAvatar" :class="{ isToday: isToday(addDays(day.date, 0)), isIpgo1: isIpgo1(addDays(day.date, 1)) ,isIpgo2: isIpgo2(addDays(day.date, 1)),isIpgo3: isIpgo3(addDays(day.date, 1)) }">
                    {{ day.dayOfMonth }}
                  </v-avatar>
                </td>
              </tr>
            </tbody>
          </table>
          <v-avatar size="14" color="#EADFB4" />
          <b style="margin-right: 15px;"> 주문대기</b>
          
          <v-avatar size="14" color="#69d55d" />
          <b> 주문신청</b>
          </div>
        </v-window-item>

        <v-window-item value="two">
          <div class="fontNotoSans700" style="display: flex; align-items: center; justify-content: center; height: 300px;">
          준비 중
        </div>
        </v-window-item>
      </v-window>
  </v-card-text>
    </v-card >

  <v-card v-if="!isMobile" style="width:340px; min-width: 412px;" class="fontNotoSans700">
    <v-card-item>
      <v-card-title class="fontNotoSans700">
        {{ empName }} 님
      </v-card-title>
      <v-card-subtitle style="opacity: 1; border-bottom: 1px solid #c1c1c1;">
        {{ empRole }}
      </v-card-subtitle>
    </v-card-item>

    <v-card-text>
      <table class="indexUserInfoTb">
      <tbody>
        <tr class="indexUserInfoTr">
          <td>사업장 이름 </td>
          <td>{{ compName }}</td>
        </tr>
        <tr class="indexUserInfoTr">
          <td>사업장 구분 </td>
          <td>{{ compGubun }}</td>
        </tr>
        <tr class="indexUserInfoTr">
          <td>사업장 대표 </td>
          <td>{{ compBoss }} / {{ compBossCp }}</td>
        </tr>
      </tbody>
    </table>    
    <v-tabs
      v-model="tab"
      bg-color="#fff"
      class="indexTabs"
    >
      <v-tab value="one" class="indexTab1 fontNotoSans700" style="color: #fff;">주문</v-tab>
      <v-tab value="two" class="indexTab2 fontNotoSans700" style="color: #fff;">식단</v-tab>
    </v-tabs>
    <v-window v-model="tab">
        <v-window-item value="one">

          <div class="calendar"> 
            
          <div style="display: flex; align-items: center; justify-content: center;">
            <v-icon @click="previousMonth" size="26">{{ mdiChevronLeft }}</v-icon>
            <h2 style="height: 24px;">{{ currentMonth }}</h2>
            <v-icon @click="nextMonth" size="26">{{ mdiChevronRight }}</v-icon>
          </div>
          
          <table class="indexDateTable">
            <thead>
              <tr>
                <th v-for="day in daysOfWeek" :key="day" :class="{ redText: day === '일', blueText: day === '토' }">{{ day }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="week in calendar" :key="week[0].date.toISOString()">
                <td v-for="day in week" :key="day.date.toISOString()">
                  <v-avatar size="35" @click="indexDateClick(addDays(day.date, 0))" class="dayAvatar" :class="{ isToday: isToday(addDays(day.date, 0)), isIpgo1: isIpgo1(addDays(day.date, 1)) ,isIpgo2: isIpgo2(addDays(day.date, 1)),isIpgo3: isIpgo3(addDays(day.date, 1)) }">
                    {{ day.dayOfMonth }}
                  </v-avatar>
                </td>
              </tr>

            </tbody>
          </table>
          <v-avatar size="14" color="#f2d1f1" />
          <b style="margin-right: 15px;"> 주문대기</b>
          
          <v-avatar size="14" color="#69d55d" />
          <b style="margin-right: 15px;"> 주문신청</b>

          <v-avatar size="14" color="#9BB0C1" />
          <b> 접수완료</b>
          </div>
        </v-window-item>

        <v-window-item value="two">
          <div class="fontNotoSans700" style="display: flex; align-items: center; justify-content: center; height: 300px;">
          준비 중
        </div>
        </v-window-item>
      </v-window>
  </v-card-text>
    
  </v-card>
  </v-sheet>
 
</template>

<script setup lang="ts">
import axios from 'axios';
import { computed, watch ,ref, onMounted} from 'vue';
import { useStore } from 'vuex';
import {
  mdiChevronLeft,
  mdiChevronRight,
  // mdiTriangle
} from '@mdi/js'
import router from '@/router';
import { useDisplay } from 'vuetify'
const { mobile } = useDisplay()
const isMobile = mobile.value
const store = useStore();
const mainDate = computed(() => store.state.mainDate);
// const empId = computed(() => store.state.userInfoArr.emp_id);
const empName = computed(() => store.state.userInfoArr.emp_name);
const empRole = computed(() => empRoleShift(store.state.userInfoArr.emp_role));
const empRoleShift = (empRole:string) => {
  if (empRole === 'admin') {
    return '관리자';
  } else if(empRole === 'user'){
    return '영양사(일반)';
  }
};
const empId = computed(() => store.state.userInfoArr.emp_id);
const compId = computed(() => store.state.compInfoArr.comp_id);
const compName = computed(() => store.state.compInfoArr.comp_name);
const compBoss = computed(() => store.state.compInfoArr.comp_boss);
const compBossCp = computed(() => formatCp(store.state.compInfoArr.comp_boss_cp));
const compGubun = computed(() => store.state.compInfoArr.comp_gubun);

function formatCp(cpNum:any) {
    const digits = cpNum.match(/\d/g).join('');
    if (digits.length === 11) {
        return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7)}`;
    } else if (digits.length === 10) {
        return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
    }
}
watch(compName, () => {
   ipgoDateList1.value = []
   ipgoDateList2.value = []
  ipgoDateView();
  console.log(compName.value)
});
watch(mainDate, () => {
  console.log(mainDate.value)
});
const tab = ref(null);
import { startOfMonth, addMonths, format, isToday, getDay, subDays, getDaysInMonth } from 'date-fns';

const currentDate = ref(new Date());
const daysOfWeek = ['월', '화', '수', '목', '금', '토', '일'];

const currentMonth = computed(() => format(currentDate.value, 'yyyy년 MM월'));

const calendar = computed(() => {
  const startOfMonthDate = startOfMonth(currentDate.value);
  const startOfCalendar = subDays(startOfMonthDate, getDay(startOfMonthDate) - 1);
  const weeks = [];
  
  let currentDay = new Date(startOfCalendar);
  const daysInMonth = getDaysInMonth(currentDate.value);

  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      week.push({
        date: new Date(currentDay),
        dayOfMonth: currentDay.getDate()
      });
      currentDay.setDate(currentDay.getDate() + 1);
    }
    weeks.push(week);
    // 만약 현재 주가 마지막 주이고, 마지막 주의 마지막 날이 해당 월의 마지막 날이 아니면 더 이상 추가하지 않음
    if (i === 5 && week[0].dayOfMonth !== daysInMonth) break;
  }

  return weeks;
});

const previousMonth = () => {
  currentDate.value = addMonths(currentDate.value, -1);
};

const nextMonth = () => {
  currentDate.value = addMonths(currentDate.value, 1);
};
const isIpgo3 = (date:any) => {
  return ipgoDateList3.value.includes(date.toISOString().slice(0, 10));
};

const isIpgo2 = (date:any) => {
  return ipgoDateList2.value.includes(date.toISOString().slice(0, 10));
};

const isIpgo1 = (date:any) => {
  return ipgoDateList1.value.includes(date.toISOString().slice(0, 10));
};

const ipgoDateList1 = ref([] as any[]);
const ipgoDateList2 = ref([] as any[]);
const ipgoDateList3 = ref([] as any[]);

const ipgoDateView = async () => {
  try {
    const response = await axios.get(`/api/ipgoDateView/${empId.value}/${compId.value}`);
    const responseData = response.data;
    if (Array.isArray(responseData)) {
      responseData.forEach(item => {
        if (item.ij_status === 1) {
          ipgoDateList1.value.push(item.ij_ipgo_date);
        } else if (item.ij_status === 2) {
          ipgoDateList2.value.push(item.ij_ipgo_date);
        } else if(item.ij_status === 3){
          ipgoDateList3.value.push(item.ij_ipgo_date);
        }
      });
      return { ipgoDateList1: ipgoDateList1.value, ipgoDateList2: ipgoDateList2.value, ipgoDateList3: ipgoDateList3.value }; // 가져온 데이터를 반환
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};


onMounted(async () => {
  ipgoDateView();
});

const indexDateClick = async (date:any) => {
  const dateObject = new Date(date);
  dateObject.setDate(dateObject.getDate() + 1); // 날짜를 하루 빼줍니다.
  const dateString = dateObject.toISOString().split('T')[0]; // ISO 문자열에서 'T'를 기준으로 나누어 첫 번째 부분만 남깁니다.
  store.commit('setMainDate', dateString);
  await jumunSelect(dateString);
  if(jumunList.value.length > 0){
    router.push('/headMenu3');
  }else{
    router.push('/headMenu2');
  }
}

const addDays = (date:any, days:any) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const jumunList = ref([] as any[]);

const jumunSelect = async (dateString:any) => {
  try {
    const response = await axios.get(`/api/jumunSelect/${empId.value}/${compId.value}/${dateString}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      jumunList.value = responseData;
      return responseData; // 가져온 데이터를 반환

    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};
</script>

<style scoped>
.indexTabs{
  height: 30px;
  border-bottom: 1px solid #0f7545;
}
.indexTab1,.indexTab2{
  width: 50%;
  max-height: 31px;
}
.indexTab1:hover ,.indexTab2:hover {
  background-color: rgb(9, 107, 70);
  color: #000000;
}
.v-slide-group-item--active{
  background-color: #0f7545 !important;
  color: #fff !important;
}
.indexUserInfoTb{
  margin-bottom: 13px;
}
.indexUserInfoTr td:first-child {
  width:90px;
}
.indexUserInfoTr td:last-child {
  text-align: left;
}
.calendar button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.indexDateTable {
  margin: 10px auto;
}

.indexDateTable th, .indexDateTable td {
  padding: 5px;
}
.indexDateTable td {
  font-size: 17px;
}
.calendar {
  margin-top: 15px;
  text-align: center;
}

.isToday {
  font-weight: bold;
  border: 2px solid #333;
}

.v-window__container{
  height: calc(100% - 16px);
}

.v-icon:active {
  transform: translateY(1.2px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}
.redText {
  color: red;
}
.blueText{
  color: blue;
}
.dayAvatar{
  color:#000000;
  cursor: pointer;
}

.dayAvatar:active {
  background-color: rgb(191, 209, 224);
  cursor: pointer;
  transform: translateY(3px);
}
.isIpgo3{
  background-color: #9BB0C1;
}
.isIpgo2{
  background-color: #69d55d;
}
.isIpgo1{
  background-color: #f2d1f1 !important;
}
</style>