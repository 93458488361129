// store/index.ts

import { createStore } from 'vuex';
import CryptoJS from 'crypto-js';

// 암호화 키 (반드시 안전한 곳에 보관되어야 함)
const ENCRYPTION_KEY = 'asdfqweer123124';

const encrypt = (data:any) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  return encryptedData;
};

const decrypt = (encryptedData:any) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

const store = createStore({
  state: {
    role: null,
    drawer: false,
    userInfoArr: [], 
    compInfoArr: [],
    zzimListArr: [] as any[],
    mainDate: '',
    searchItem: [],
  },
  mutations: {
    setRole(state, role) {
      state.role = role;
      sessionStorage.setItem('vuex_state', encrypt(state));
    },
    setDrawer(state, value) {
      state.drawer = value;
    },
    clearRole(state) {
      state.role = null;
    },
    setUserInfoArr(state, userInfoArr) {
      state.userInfoArr = userInfoArr;
      sessionStorage.setItem('userInfoList', encrypt(state));
    },
    setCompInfoArr(state, compInfoArr) {
      state.compInfoArr = compInfoArr;
      sessionStorage.setItem('compInfoList', encrypt({ compInfoArr }));
    },
    setZzimListArr(state, zzimListArr) {
      state.zzimListArr = state.zzimListArr || [];
      state.zzimListArr = state.zzimListArr.concat(zzimListArr);
      sessionStorage.setItem('zzimList', encrypt(state));
    },
    setMainDate(state, mainDate) {
      state.mainDate = mainDate;
      sessionStorage.setItem('mainDateInfo', encrypt(state));
    },
    setSearchItem(state, searchItem) {
      state.searchItem = searchItem;
      sessionStorage.setItem('searchItem', encrypt({ searchItem }));
    },
    removeFromZzimList(state, zzim) {
      const index = state.zzimListArr.findIndex(item => item.iu_idx === zzim.iu_idx);
      console.log(zzim.iu_name,index)
      if (index !== -1) {
        state.zzimListArr.splice(index, 1);
        sessionStorage.setItem('zzimList', encrypt(state));
      }
    },
  },
  actions: {
    role({ commit }, { role }) {
      commit('setRole', role);
    },
    Drawer({ commit, state }) {
      commit('setDrawer', !state.drawer);
    },
    logout({ commit }) {
      commit('clearRole');
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.role,
    isAdmin: (state) => state.role === 'admin',
  },
});

// 페이지 로딩 시 sessionStorage에서 저장된 암호화된 상태를 복호화하여 불러옵니다.
const savedState = sessionStorage.getItem('vuex_state');
if (savedState) {``
  store.replaceState(decrypt(savedState));
}

const savedUserInfoArr = sessionStorage.getItem('userInfoList');
if (savedUserInfoArr) {
  store.commit('setUserInfoArr', decrypt(savedUserInfoArr).userInfoArr);
}

const savedCompInfoArr = sessionStorage.getItem('compInfoList');
if (savedCompInfoArr) {
  store.commit('setCompInfoArr', decrypt(savedCompInfoArr).compInfoArr);
}

const savedZzimListArr = sessionStorage.getItem('zzimList');
if (savedZzimListArr) {
  store.commit('setZzimListArr', decrypt(savedZzimListArr).zzimListArr);
}

const savedmainDateInfo = sessionStorage.getItem('mainDateInfo');
if (savedmainDateInfo) {
  store.commit('setMainDate', decrypt(savedmainDateInfo).mainDate);
}

const savedSearchItem = sessionStorage.getItem('searchItem');
if (savedSearchItem) {
  store.commit('setSearchItem', decrypt(savedSearchItem).searchItem);
}
export default store;
