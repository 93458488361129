<template>
  <v-app id="inspire">
    <v-app-bar flat style="border-bottom: 1px solid #c1c2c5;">
      <v-container id="MainLayoutContainer" class="mx-auto d-flex align-center justify-center">
          <img class="mainLogoImg" src="@/assets/pictures/hamwooriLogo.png" @click="indexPage"/>

        <v-spacer></v-spacer>
        <div style="display: flex; align-items: center;">
            <v-text-field 
              class="mainSearchDate"
              type="date"
              density="compact" 
              flat 
              hide-details 
              single-line 
              variant="filled"
              v-model="itemIpgoDate" 
              shaped 

              ></v-text-field>
            </div>


            <v-text-field 
            class="mainSearchItem"
              density="compact" 
              flat 
              hide-details="auto" 
              label="품목을 검색해주세요." 
              single-line
              clearable
              variant="filled"
              :append-inner-icon="mdiMagnify"
              :searchLoading="searchLoading"
              v-model="searchItem" 
              @click:append-inner="goSearch"
              @keydown.enter="onEnter"
              ></v-text-field>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                color="primary"
                v-bind="props"
                class="headerRight"
                style="letter-spacing: -0.5px; min-width:0; height: 100%; padding: 0px;"
              >
                {{ compInfoList.comp_name }}
                <v-icon :style="{ transform: 'rotate(90deg)' }">{{ mdiAutorenew }}</v-icon>
              </v-btn>
              ㅣ
            </template>
            <v-list class="mainLayoutList">
              <v-list-item
                v-for="(comp, index) in compInfoArr"
                :key="index"
                :value="index"
                style="letter-spacing: -0.5px; min-width:0; height: 100%;"
                 @click="() => changeComp(comp)"
                >{{ comp.comp_name }}
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu  v-if = "!uploadLoading">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                class="headerRight"
                style="letter-spacing: -0.5px; min-width:0;  height: 100%; padding: 0px;"
                
              >
              {{ account.emp_name || '' }}님
              </v-btn>
              ㅣ
            </template>
            <v-list class="mainLayoutList">
              <v-list-item
              @click="mgtIndexPageMove"
              :append-icon="mdiLogoutVariant"
              style=""
              v-if="isAdmin"
              >
                관리자페이지
              </v-list-item>
              <v-list-item
              @click="submitForm"
              :append-icon="mdiLogoutVariant"
              style=""
              v-if="isAdmin"
              >
                상품목록 업데이트
              </v-list-item>
              <v-list-item
              @click="logout"
              :append-icon="mdiLogoutVariant"
              style="">
                로그아웃
              </v-list-item>
            </v-list>
          </v-menu>
        
        <v-icon class="mainCartIcon" @click="toggleDrawer" size="x-large" v-if="zzimListLength === 0"> {{ mdiCartOutline }}</v-icon>

        <v-badge class="mainCartIcon" :content=zzimListLength color="error" floating  @click="toggleDrawer" v-if="zzimListLength > 0" style="cursor: pointer;">
          <v-icon size="x-large"> {{ mdiCartOutline }}</v-icon>
        </v-badge>
      </v-container>
    </v-app-bar>
    <v-app-bar id="MenuLayoutAppBar">
      <v-container id="MenuLayoutContainer" class="mx-auto d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-menu v-for="(section, index) in sections" :key="index">
          <template v-slot:activator="{ props }">
            <v-btn rounded="lg" class="mainMenuBtn align-self-center"
              height="100%" v-bind="props" :to="`/${section.link.toLowerCase()}`"
              :class="{ 'v-btn--active': isActive(section.link) }">
              {{ section.title }}
            </v-btn>
          </template>
        </v-menu>
        <v-spacer></v-spacer>

      </v-container>
    </v-app-bar>
    <NavigationBar />

    <v-main id="shopMain" v-if = "!uploadLoading">
            <slot />
    </v-main>
    <v-main>
    <div v-if="uploadLoading" class="spinner tac">

    </div>
  </v-main>
  <v-footer style="padding: 0; max-height:56px !important; border-top: 1px solid #dad7cd !important;" >
      

    <div class="px-4 py-2 text-center w-100">
      <v-btn
        class="mx-4"
        size="small"
        variant="plain"
      >
    <span class="fontNotoSans700" style=" color: #344e41;">   {{ new Date().getFullYear() }}-shop</span>
  </v-btn>
    </div>
  </v-footer>
  </v-app>
</template>

<script setup lang="ts">
import router from '@/router';
import {
  mdiLogoutVariant,
  mdiCartOutline,
  mdiMagnify,
  mdiAutorenew,
} from '@mdi/js'
import NavigationBar from './NavigationBar.vue';
import { useStore } from 'vuex';
import { ref, onMounted, watch, computed } from 'vue';
import axios from 'axios';
const store = useStore();
const isAdmin = store.getters.isAdmin;

const itemIpgoDate = ref('')
const searchItem = ref(null)
const uploadLoading = ref(false);
const loaded = ref(false)
const searchLoading = ref(false)


const account = ref({
  emp_id: null,
  emp_name: '',
  emp_role: '',
});
const compInfoList = ref({
  comp_id : '',
  comp_name: '',
  emp_id: '',
  comp_email: '',
  comp_tell: '',
  comp_boss: '',
  comp_boss_cp: '',
  comp_gubun: '',
  comp_status: 0,
  comp_percent: 0,
});

const logout = () => {
  axios.delete("/api/account").then(() => {
    alert("로그아웃하였습니다.");
    account.value.emp_id = null;
    account.value.emp_name = "";
    account.value.emp_role = "";
    sessionStorage.removeItem('vuex_state');
    sessionStorage.removeItem('zzimList');
    sessionStorage.removeItem('mainDateInfo');
    sessionStorage.removeItem('userInfoList');
    sessionStorage.removeItem('compInfoList');
    sessionStorage.removeItem('searchItem');

    window.location.reload();
  });
};
const toggleDrawer = () => {
  store.commit('setDrawer', !store.state.drawer);
};
const mgtIndexPageMove = () => {
  const screenWidth = window.screen.width; // 화면의 너비
  const screenHeight = window.screen.height; // 화면의 높이

  const width = Math.round(screenWidth *0.8); // 화면 너비의 5분의 3
  const height = Math.round(screenHeight *0.8); // 화면 높이의 5분의 3

  const left = (screenWidth - width) / 2; // 화면 가운데 정렬을 위한 left 값
  const top = (screenHeight - height) / 2; // 화면 가운데 정렬을 위한 top 값

  const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`;

  // 새 창 열기
  localStorage.setItem('currentPageTitle', '관리자페이지');
  window.open("#/MgtIndexPage", "_blank", options);
};


const goSearch = () => {
  searchLoading.value = true
  
  const mainSearch = {
    mainIpgoDate: itemIpgoDate.value,
    searchItem: searchItem.value
};
  store.commit('setSearchItem', mainSearch);
  router.push('/headMenu2');

  setTimeout(() => {
    searchLoading.value = false
    loaded.value = true
  }, 500)
}
const sections = [
  { title: '공지', link: 'HeadMenu1' },
  { title: '상품', link: 'HeadMenu2' },
  { title: '주문', link: 'HeadMenu3' },
  { title: '설정', link: 'HeadMenu4' },
];
function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


watch(itemIpgoDate, (newValue: any) => {
  if (newValue) {
    const inputDate = new Date(newValue);
    itemIpgoDate.value = formatDate(inputDate);
    store.commit('setMainDate', itemIpgoDate.value);
  }
});

const changeMainDate = computed(() => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  const mainDateComputed = store.state.mainDate
    return mainDateComputed;
});

watch(changeMainDate, (newValue: any) => {
      //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  if (newValue) {
    itemIpgoDate.value = newValue;
  }
});

const zzimListLength = computed(() => {
  // store.state.zzimListArr에서 comp_id가 'nemo'인 값만 필터링
  const filteredList = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate:string; }) => item.comp_id ===store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
  // 필터링된 배열의 길이 반환
  return filteredList.length;
});

const indexPage = () => {
  router.push('/indexPage');
}
const isActive = (link: any) => {
  const currentPath = router.currentRoute.value.path.toLowerCase();
  const includes = currentPath.includes(`/${link.toLowerCase()}`);
  return includes;
};

const onEnter = () => {
  goSearch();
};

const compInfoArr = ref([] as any[]);
const selectAllCompInfo = async () => {
  try {
    const response = await axios.get(`/api/selectAllCompInfo/${store.state.userInfoArr.emp_id}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      compInfoArr.value = responseData;
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};
const changeComp = (comp:any) => {
  const newCompInfoArr = comp;
  store.commit('setCompInfoArr', newCompInfoArr);
  compInfoList.value = store.state.compInfoArr;
  // window.location.reload();
};

const submitForm = async (): Promise<void> => {
  try {
    uploadLoading.value = true;
    await axios.post('/api/crawl/dure_item');
    console.log('크롤링이 성공적으로 마무리되었습니다.')
    sessionStorage.removeItem('zzimList');
    window.location.reload();
  } catch (error) {
    console.error('크롤링이 실패하였습니다. :', error);
  } finally {
    uploadLoading.value = false;
  }
};

onMounted(async () => {
  selectAllCompInfo();
  account.value = store.state.userInfoArr;
  compInfoList.value = store.state.compInfoArr;
  itemIpgoDate.value = store.state.mainDate;

});
</script>
<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 100px auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.v-field--variant-filled{
  border-top-left-radius: 500% !important;
}
</style>