<template>
  <v-card class="itemListMainCard" elevation="3" style="padding: 0 20px 0 10px;">

    <v-row class="itemPageVrow">
      <v-col cols="12" class="tac itemListH1" style="margin-top: 20px;">
        <h1 class="fontNotoSans700 itemListH1" style="color: #454545;">상품목록</h1>
      </v-col>

      <v-col cols="12" style="padding-bottom:0;">
        <table class="jogunDateTable">
          <tbody>
            <tr>
              <td class="jumunJogunSelectTd">입고일</td>
              <td class="jumunJogunSelectSecondTd">
                <div style="display: flex; align-items: center;">
                  <v-icon @click="decrementDate">{{ mdiChevronLeft }}</v-icon>
                  <v-text-field type="date" variant="outlined" v-model="itemIpgoDate" density="compact" hide-details
                    shaped></v-text-field>
                  <v-icon @click="incrementDate">{{ mdiChevronRight }}</v-icon>
                </div>
              </td>
              <td class="jumunJogunSelectTd">품목구분</td>
              <td>
                <div style="display: block;">
                  <select v-model="selectedBigCate" @change="dynamicFilter" class=" dureJogunSelect">
                    <option class="dureJogunSelectOption" value="all" label="품목분류(전체)"></option>
                    <option class="dureJogunSelectOption" value="가공식품" label="가공식품"></option>
                    <option class="dureJogunSelectOption" value="간식" label="간식"></option>
                    <option class="dureJogunSelectOption" value="간식거리" label="간식거리"></option>
                    <option class="dureJogunSelectOption" value="건어물" label="건어물"></option>
                    <option class="dureJogunSelectOption" value="늘푸른 김치" label="김치"></option>
                    <option class="dureJogunSelectOption" value="농산물" label="농산물"></option>
                    <option class="dureJogunSelectOption" value="수산물" label="수산물"></option>
                    <option class="dureJogunSelectOption" value="주방(생활용품)" label="주방(생활)용품"></option>
                    <option class="dureJogunSelectOption" value="축산물" label="축산물"></option>
                  </select>
                  <select v-model="selectedJaego" @change="dynamicFilter" class=" dureJogunSelect" label="">
                    <option class="dureJogunSelectOption" value="all" label="재고(전체)"></option>
                    <option class="dureJogunSelectOption" value="O" label="재고: O"></option>
                    <option class="dureJogunSelectOption" value="X" label="재고: X"></option>
                  </select>
                  <select v-model="selectedIsuse" @change="dynamicFilter" class=" dureJogunSelect" label="">
                    <option class="dureJogunSelectOption" value="all" label="사용유무(전체)"></option>
                    <option class="dureJogunSelectOption" value="O" label="사용: O"></option>
                    <option class="dureJogunSelectOption" value="X" label="사용: X"></option>
                  </select>
                  <select v-model="selectedDday" @change="dynamicFilterDday" class=" dureJogunSelect" label="">
                    <option class="dureJogunSelectOption" value="all" label="주문가능여부(전체)"></option>
                    <option class="dureJogunSelectOption" value="O" label="주문 가능"></option>
                    <option class="dureJogunSelectOption" value="X" label="주문 불가능"></option>
                  </select>
                    <v-icon class="itemListFormChanger" @click="toggleView">
                    {{ isBoxForm ? mdiFormatListBulletedType : mdiViewGrid }}
                    </v-icon>
                </div>
              </td>
              <td class="tar">
               
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="padding-top:0;">

        <v-table class="itemUploadTopTable fontNotoSans400" style="width:100%; border-bottom: 1px solid #e2e2e2;">
          <tbody>
            <tr>
              <td style="width:100%;">
                <v-text-field class="itemPageSearchInput" style="max-width:300px; width:auto; min-width:70px;"
                  v-model="search" color="#0f7545" label="검색" single-line clearable :append-inner-icon="mdiMagnify" flat
                  hide-details="auto" variant="outlined" density="compact">
                </v-text-field>
                <v-checkbox class="mItemListRecentOrder fontNotoSans400" style="max-width: 100px; color: green;"
                label="최근주문" v-model="recentOrderName" @change="recentOrderNameFunc" hide-details />
                <v-icon class="mItemListFormChanger" @click="toggleView" style="float:right; margin-top:1px; font-size: 25px;">
                    {{ isBoxForm ? mdiFormatListBulletedType : mdiViewGrid }}
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
        <div>
          <div v-if="crawlDureItem.length === 0" class="fontNotoSans700"
            style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">
            자료가 없습니다.</div>

            <!-- 모바일용 UI -->
              <!-- 모바일용 형식 -->
              <template v-if="isBoxForm">
                <!-- 모바일용 아이템들 -->
                <v-row no-gutters>
                  <v-col v-for="(item, index) in displayedItems" :key="index" cols="4" xs="4" sm="3" md="2" lg="2" xl="1" xxl="1">
                    <!-- 아이템 카드 -->
                    <v-card class="mx-auto mItemListCard fontNotoSans400">
                      <!-- 토글 영역 -->
                      <div class="mToggleShow" @click="toggleShow(item)">
                        <!-- 이미지 박스 -->
                        <div class="mImgBox">
                          <v-img v-if="item.iu_photo && item.iu_photo.includes('/_files')" height="100%" width="auto" :src="`http://www.xn--hu1bo4e.com/` + item.iu_photo || ''" cover ></v-img>
                          <v-img v-else-if="item.iu_photo && !item.iu_photo.includes('/_files')" height="100%" width="auto" :src="currentUrl + `/public/static/itemPhoto/` + item.iu_photo || ''" cover ></v-img>
                          <div v-else style="height: 100%; display: flex; align-items: center; justify-content: center;" class="fontNotoSans700">
                            <img style="width: 100%; text-align: center;" src="@/assets/pictures/not_img.png" />

                          </div>
                        </div>

                        <!-- 카드 제목 -->
                        <v-card-title class="line-clamp-2" v-if="item.recent_order_name === item.iu_name">
                          ❤️{{ item.iu_name || '' }}
                        </v-card-title>
                        <v-card-title class="line-clamp-2" v-else>
                          {{ item.iu_name || '' }}
                        </v-card-title>

                        <!-- 카드 서브타이틀 -->
                        <v-card-subtitle>
                          <span class="mItemBrand">{{ item.iu_brand }}</span>
                          <span>{{ addComma(item.iu_total_price + (compPercent/100 * item.iu_total_price)) || 0 }}원</span>
                        </v-card-subtitle>
                      </div>

                      <!-- 카드 액션 영역 -->
                      <v-card-actions>
                        <!-- 장바구니 아이콘 -->
             
                        <v-btn :icon="item.show ? mdiChevronUp : mdiChevronDown" @click="toggleShow(item)"></v-btn>
                        
                        <v-spacer></v-spacer>

                        <!-- 펼치기/접기 버튼 -->
                        <v-avatar class="itemCartAvatarL" v-if="isItemInZzimList(item.iu_name) && (getDdayDate(item.iu_Dday) <= itemIpgoDate)" size="45"
                          color="#0F7545">
                          <v-icon>{{ mdiCartOutline }}</v-icon>
                        </v-avatar>
                        <span class="fontNotoSans400" v-else-if="getDdayDate(item.iu_Dday) > itemIpgoDate" style="font-size: 12px;  color:red;">
                          D-{{ item.iu_Dday }} / 주문불가
                        </span>
                        <v-avatar class="itemCartAvatarS" v-else size="40" color="#EFEBE9" style="cursor: pointer;" @click="() => zzim(item)">
                        <v-icon>{{ mdiCartOutline }}</v-icon>
                      </v-avatar>
                      </v-card-actions>

                      <!-- 펼치기/접기 컨텐츠 -->
                      <v-expand-transition>
                        <div v-show="item.show">
                          <v-divider></v-divider>
                          <v-card-text class="mCardActions">
                            <p>주문: 입고일로부터 {{ item.iu_Dday }}일 전 가능</p>
                            <p>재고: {{ item.iu_jaego }}</p>
                            <p>분류: {{ item.iu_cate_big }}</p>
                            <p>분류: {{ item.iu_cate_small }}</p>
                            <p>사용: {{ item.iu_isuse }}</p>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
                <v-pagination v-model="currentPage" :length="numberOfPages" rounded="circle"></v-pagination>
              </template>

            <!-- PC용 UI -->
            <template v-else>
              <!-- list 형식 -->
              <v-data-table fixed-header :headers="headers" :items="filteredItems" item-value="name" :search="search"
                class="itemListTable fontNotoSans700" v-if="crawlDureItem.length !== 0">
                <template v-slot:item="{ item, index }">
                  <tr id="itemListbodyTr" :class="{ 'highlighted-row': isItemInZzimList(item) }">
                    <td class="tac" style="max-width:50px;">{{ index + 1 }}</td>
                    <td class="tal itemListImg">
                      <v-img v-if="item.iu_photo && item.iu_photo.includes('/_files')" :src="`http://www.xn--hu1bo4e.com/` + item.iu_photo || ''"></v-img>
                      <v-img v-else-if="item.iu_photo && !item.iu_photo.includes('/_files')" :src="currentUrl + `/public/static/itemPhoto/` + item.iu_photo || ''"></v-img>
                      <img v-else style="width: 100%; text-align: center;" src="@/assets/pictures/not_img.png" />
                    </td>
                    <td class="tal itemIubrand" style="max-width:130px;">{{ item.iu_brand || '' }}</td>

                    <td class="tal" style="max-width:300px;" v-if="item.recent_order_name === item.iu_name">
                      ❤️ {{ item.iu_name || '' }}
                    </td>
                    <td class="tal" style="max-width:300px;" v-else>{{ item.iu_name || '' }}</td>
                    <td class="tac" style="max-width:100px;">{{ item.iu_Dday || '' }} 일 <br>
                      <span class="fontNotoSans400" v-if="getDdayDate(item.iu_Dday) > itemIpgoDate" style="font-size: 12px;  color:red;">주문불가</span>
                    </td>
                    <td class="tac" style="max-width:100px;">{{ item.iu_jaego || '' }}</td>
                    <td class="tac" style="max-width:110px;">{{ item.iu_cate_big || '' }}</td>
                    <td class="tac" style="max-width:150px;">{{ item.iu_cate_small || '' }}</td>
                    <td class="tar" style="max-width:110px;">{{ addComma(item.iu_total_price + (compPercent/100 * item.iu_total_price)) || 0 }} 원</td>
                    <td class="tac" style="max-width:100px;">{{ item.iu_isuse || '' }}</td>
                    <td class="tac" style="max-width:100px; cursor: pointer;"  @click="() => zzim(item)">
                      <v-avatar class="itemCartAvatarL" v-if="isItemInZzimList(item.iu_name) && (getDdayDate(item.iu_Dday) <= itemIpgoDate)" size="45" color="#0F7545">
                        <v-icon>{{ mdiCartOutline }}</v-icon>
                      </v-avatar>
                      <span class="fontNotoSans400" v-else-if="getDdayDate(item.iu_Dday) > itemIpgoDate" style="font-size: 12px; color:red;">
                        주문불가
                      </span>
                      <v-avatar class="itemCartAvatarS" v-else size="40" color="#EFEBE9">
                        <v-icon>{{ mdiCartOutline }}</v-icon>
                      </v-avatar>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </div>
      </v-col>
    </v-row>
  </v-card>
  <!-- <transition name="fade" appear>
      <v-avatar v-if="!isAtTop" class="pageScrollToTop" size="40" color="#ccc" @click="scrollToTop">
        <v-icon>{{ mdiTriangle }}</v-icon>
      </v-avatar>
    </transition> -->
  <v-snackbar v-model="snackbar" :timeout="3000">
    {{ snackbarText }}

    <template v-slot:actions>
      <v-btn color="#fff" variant="text" @click="toggleDrawer">
        열기
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script setup lang="ts">
import { ref, onMounted, computed, watch, onBeforeUnmount } from 'vue';
import axios from 'axios';
import {
  mdiMagnify,
  mdiCartOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChevronDown,
  mdiViewGrid,
  mdiFormatListBulletedType
  // mdiTriangle
} from '@mdi/js'
import { useStore } from 'vuex';

import { useDisplay } from 'vuetify'
const { mobile } = useDisplay()
const isMobile = mobile.value

const store = useStore();
const crawlDureItem = ref([] as any[]);
const crawlDureItem2 = ref([] as any[]);
const currentUrl = ref('');

const selectedDday = ref('all');
const selectedIsuse = ref('all');
const selectedBigCate = ref('all');
const selectedJaego = ref('all');
const zzimListView = ref([] as any[]);
const itemIpgoDate = ref('')
let snackbar = ref(false);
const account = ref({
  emp_id: null,
  emp_name: '',
  emp_role: '',
});

const compId = computed(() => store.state.compInfoArr.comp_id);
const compPercent = computed(() => store.state.compInfoArr.comp_percent);

const mainDate = computed(() => store.state.mainDate);

const search = ref(store.state.searchItem.searchItem || '');

// Vuex 상태 변화 감지
watch(() => store.state.searchItem.searchItem, (newValue) => {
  search.value = newValue;
});

const headers = ref([
  { title: 'No.', key: 'index', align: 'center' },
  { title: '이미지', key: 'iu_photo', align: 'center', sortable: false },
  { title: '업 체', key: 'iu_brand', align: 'center', sortable: true },
  { title: '상 품', key: 'iu_name', align: 'center', sortable: true },
  { title: '상품준비기간', key: 'iu_Dday', align: 'center', sortable: true },
  { title: '재 고', key: 'iu_jaego', align: 'center', sortable: true },
  { title: '대분류', key: 'iu_cate_big', align: 'center', sortable: true },
  { title: '소분류', key: 'iu_cate_small', align: 'center', sortable: true },
  { title: '판매가', key: 'iu_total_price', align: 'center', sortable: true },
  { title: '사 용', key: 'iu_isuse', align: 'center', sortable: true },
  { title: '장바구니', key: 'zzim', align: 'center', sortable: false },

] as any[]);

const recentOrderName = ref(false);
const recentOrderNameFunc = () => {
  if (recentOrderName.value) {
    crawlDureItem.value = crawlDureItem.value.filter(item => item.iu_name === item.recent_order_name);
  } else {
    crawlDureItem.value = crawlDureItem2.value
  }
};

const zzim = (item: any) => {
  const compItem = {
    ...item, // 기존 item 객체의 속성을 모두 가져옴
    iuRealTotalPrice : item.iu_total_price,
    comp_id: store.state.compInfoArr.comp_id,
    zzimDate: itemIpgoDate.value,
    iu_price: item.iu_price + (compPercent.value/100 * item.iu_price),
    iu_supply_price : item.iu_supply_price + (compPercent.value/100 * item.iu_supply_price),
    iu_vat_price : item.iu_vat_price + (compPercent.value/100 * item.iu_vat_price),
    iu_total_price : item.iu_total_price + (compPercent.value/100 * item.iu_total_price),
  };
  store.commit('setZzimListArr', compItem);
  console.log(compItem)
  snackbar.value = true;
  // toggleDrawer();
};


const toggleDrawer = () => {
  store.commit('setDrawer', true);
  snackbar.value = false;
};
const snackbarText = '장바구니에 추가되었습니다.';
const dynamicFilter = () => {
  let filteredData = crawlDureItem2.value.slice(); // 원본 데이터를 수정하지 않기 위해 복사
  // '재고'가 'O'인 경우
  if (selectedJaego.value === 'O') {
    filteredData = filteredData.filter(item => parseInt(item.iu_jaego) >= 1);
  }
  // '재고'가 'X'인 경우
  else if (selectedJaego.value === 'X') {
    filteredData = filteredData.filter(item => parseInt(item.iu_jaego) < 1);
  }
  const filters: Record<string, string> = {
    iu_isuse: selectedIsuse.value,
    iu_cate_big: selectedBigCate.value,
    // 다른 필터를 추가할 수 있음
  };
  // filters 객체를 순회하면서 필터를 적용
  for (const key in filters) {
    const value = filters[key];
    if (value !== 'all') {
      filteredData = filteredData.filter(item => {
        if (key === 'iu_jaego' && selectedJaego.value === 'O') {
          return parseInt(item[key]) >= 1;
        } else if (key === 'iu_jaego' && selectedJaego.value === 'X') {
          return parseInt(item[key]) < 1;
        } else {
          return item[key] === value;
        }
      });
    }
  }
  return filteredData; // 필터링된 데이터 반환
};

const dynamicFilterDday = () => {
  let filteredData = crawlDureItem2.value.slice(); // 원본 데이터를 수정하지 않기 위해 복사
  
  if (selectedDday.value === 'O') {
    filteredData = filteredData.filter(item => (getDdayDate(item.iu_Dday) <= itemIpgoDate.value) && (parseInt(item.iu_Dday) >= 1));
  }

  else if (selectedDday.value === 'X') {
    filteredData = filteredData.filter(item => (getDdayDate(item.iu_Dday) > itemIpgoDate.value) && parseInt(item.iu_Dday) >= 1);
  }

  const filters: Record<string, string> = {
    iu_Dday: selectedDday.value,
  };

  // filters 객체를 순회하면서 필터를 적용
  for (const key in filters) {
    const value = filters[key];
    if (value !== 'all') {
      filteredData = filteredData.filter(item => {
        if (key === 'iu_Dday' && selectedDday.value === 'O') {
          return parseInt(item[key]) >= 1;
        } else if (key === 'iu_Dday' && selectedDday.value === 'X') {
          return parseInt(item[key]) >= 1;
        } else {
          return item[key] === value;
        }
      });
    }
  }
  return filteredData; // 필터링된 데이터 반환
};

// 두 함수의 결과를 합치는 함수
const applyFilters = () => {
  const filteredData1 = dynamicFilter();
  const filteredData2 = dynamicFilterDday();
  // 두 결과를 합침
  const combinedData = filteredData1.filter(item1 => filteredData2.some(item2 => item1 === item2));
  crawlDureItem.value = combinedData;
};



// Dday 값을 현재 날짜에 더한 값을 반환하는 함수
function getDdayDate(Dday : any) {
    const now = new Date();
    const dday = new Date(now.getTime() + Dday * 24 * 60 * 60 * 1000); // 현재 날짜에 iu_Dday일을 더한 값 = iu_ipgo_date
    const year = dday.getFullYear();
    const month = String(dday.getMonth() + 1).padStart(2, '0'); 
    const day = String(dday.getDate()).padStart(2, '0'); 
    return `${year}-${month}-${day}`;
}

function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const decrementDate = () => {
  const currentDate = new Date(itemIpgoDate.value);
  currentDate.setDate(currentDate.getDate() - 1);
  itemIpgoDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoDate.value);
};

const incrementDate = () => {
  const currentDate = new Date(itemIpgoDate.value);
  currentDate.setDate(currentDate.getDate() + 1);
  itemIpgoDate.value = formatDate(currentDate);
  store.commit('setMainDate', itemIpgoDate.value);
};

watch(itemIpgoDate, (newValue: any) => {
  if (newValue) {
    const inputDate = new Date(newValue);
    itemIpgoDate.value = formatDate(inputDate);
    store.commit('setMainDate', itemIpgoDate.value);
  }
});

const changeMainDate = computed(() => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  applyFilters();// 날짜바꿔도 필터링유지
  const mainDateComputed = store.state.mainDate
  return mainDateComputed;
});

watch(changeMainDate, (newValue: any) => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  if (newValue) {
    itemIpgoDate.value = newValue;
    selectItemUpload();
  }
});

const changComp = computed(() => {

  const mainComputed = store.state.compInfoArr.comp_id
  return mainComputed;
});

watch(changComp, (newValue: any) => {


  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  if (newValue) {
    selectItemUpload();
  }
});

const selectItemUpload = async () => {
  const empId = computed(() => store.state.userInfoArr.emp_id);

  try {
    const response = await axios.get(`/api/selectItemUpload/${empId.value}/${compId.value}/${mainDate.value}`);
    const responseData = response.data;
    currentUrl.value = window.location.origin; // 현재 호스트와 프로토콜을 가져옴
    if(currentUrl.value === 'http://hamwoori.co.kr'){
      currentUrl.value = 'http://61.253.115.156:19919'
    }else{
      currentUrl.value = 'http://49.247.8.206:3001'
    }
    if (Array.isArray(responseData)) {
      crawlDureItem.value = responseData;
      crawlDureItem2.value = responseData;
      return responseData; // 가져온 데이터를 반환
      
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};



function addComma(number: any) {
  if (isNaN(number)) return number; // 숫자가 아닌 경우 그대로 반환
  return number.toLocaleString(); // 숫자인 경우 컴마를 추가한 문자열 반환
}



const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return crawlDureItem.value;
  }
  return crawlDureItem.value.filter(item =>
    (item.iu_brand && item.iu_brand.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_name && item.iu_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_cate_big && item.iu_cate_big.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_cate_small && item.iu_cate_small.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.iu_isuse && item.iu_isuse.toLowerCase().includes(search.value.toLowerCase()))
  );
});


const isItemInZzimList = computed(() => {
  // zzimListArr 배열에서 comp_id가 compInfoArr.comp_id와 동일한 요소들만 필터링
  const filteredList = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);

  return (item: any) => {
    // 필터링된 배열에서 iu_name이 item인 요소가 있는지 확인
    return filteredList.some((subArray: { iu_name: any; }) => subArray.iu_name === item);
  };
});


watch(() => store.state.zzimListArr, () => {
  zzimListView.value = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
});




onMounted(async () => {
  selectItemUpload();
  account.value = store.state.userInfoArr;
  zzimListView.value = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
  itemIpgoDate.value = store.state.mainDate;
});




///////////////모바일///////////////모바일///////////////모바일///////////////모바일


const itemsPerPage = ref(24); // 초기 아이템 수

// 화면 크기에 따라 itemsPerPage 업데이트
const updateItemsPerPage = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 2560) { // xxl
    itemsPerPage.value = 60;
  } else if (screenWidth >= 1920 && screenWidth < 2560) { // XL 사이즈
    itemsPerPage.value = 48;
  } else if (screenWidth >= 1280 && screenWidth < 1919){ // lg
    itemsPerPage.value = 36;
  } else{
    itemsPerPage.value = 24;
  }
};
updateItemsPerPage();
watch(() => window.innerWidth, () => {
  updateItemsPerPage();
});

const currentPage = ref(1);

const numberOfPages = computed(() => {

  return Math.ceil(filteredItems.value.length / itemsPerPage.value);
});

const displayedItems = computed(() => {
  window.scrollTo({ top: 0, behavior: 'auto' }); // 페이지 맨 위로 스크롤
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return filteredItems.value.slice(startIndex, endIndex);
});

function toggleShow(item: any) {
  item.show = !item.show;
}
const isAtTop = ref(true);

// const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: 'smooth'
//   });
// };

const handleScroll = () => {
  isAtTop.value = window.pageYOffset === 0;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);

});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});


/////////////////형식변환토글
const isBoxForm = ref(isMobile); // 리스트 형식 여부
const toggleView = () => {
  isBoxForm.value = !isBoxForm.value;
};

</script>

<style scoped>
.v-icon:active {
  transform: translateY(1.2px);
  /* 클릭할 때 버튼을 아래로 이동하는 효과 등 */
}

.line-clamp-2 {
  /* 두 줄로 텍스트를 자르고 개행하도록 스타일을 지정합니다. */
  white-space: normal !important;
  /* 텍스트가 개행되도록 설정합니다. */
  line-height: 1.2;
  /* 행간 간격을 조절합니다. */
}

.mToggleShow {
  cursor: pointer;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

a .fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>