// rules.ts
export const idRule = [
  (v: string) => !!v || 'ID는 필수 항목입니다.',
  (v: string) => v.length <= 20 || 'ID는 20자 이하여야 합니다.',
];

export const emailRule = [
  (v: string) => !!v || '이메일은 필수 항목입니다.',
  (v: string) => /.+@.+\..+/.test(v) || '유효한 이메일 형식이어야 합니다.'
];

export const pwRule = [
  (v: string) => !!v || '비밀번호는 필수 항목입니다.',
  (v: string) => v.length >= 8 || '비밀번호는 최소 8자 이상이어야 합니다.'
];

export const nameRule = [
  (v: string) => !!v || '이름은 필수 항목입니다.',
  (v: string) => v.length <= 5 || '이름은 5자 이하여야 합니다.',
  (v: string) =>/^[가-힣]+$/.test(v) || '이름은 한글로만 입력해야 합니다.',
];

export const compNameRule = [
  (v: string) => !!v || '기관명은 필수 항목입니다.',
  (v: string) => v.length <= 20 || '기관명은 20자 이하여야 합니다.',
];

export const compGubunRule = [
  (v: string) => !!v || '기관 구분은 필수 항목입니다.',
  (v: string) => v.length <= 10 || '기관 구분은 10자 이하여야 합니다.',
];



export const birthdateRule = [
  (v: string) => !!v || '생년월일은 필수 항목입니다.',
  // Add additional birthdate rules here
];

export const phoneNumberRule = [
  (v: string) => !!v || '휴대폰 번호는 필수 항목입니다.',
  (v: string) => v.length <= 11 || '번호는 11자 이하여야 합니다.',
  (v: string) => /^\d+$/.test(v) || '숫자만 입력해야 합니다.',
];
export const numberRule = [
  (v: string) => v.length <= 4 || '',
  (v: string) => /^\d+$/.test(v) || '',
];

export const jungsuRule = [
  (v: string) => /^-?\d+$/.test(v) || '숫자만 입력해야 합니다.',
];


export const postalCodeRule = [
  (v: string) => !!v || '우편번호는 필수 항목입니다.',
];

export const addressRule = [
  (v: string) => !!v || '주소는 필수 항목입니다.',
];

export const detailAddressRule = [
  (v: string) => !!v || '상세주소는 필수 항목입니다.',
];

export const permissionRule = [
  (v: string) => !!v || '권한은 필수 항목입니다.',
];
export const sikdanDateRules = [
  (v: string) => !!v || '날짜를 입력해주세요.',
];
export const sikdanMealRules = [
  (v: string) => !!v || '식사시간을 선택해주세요.',
];

export const osItemNameRules =[
  (v: string) => !!v || '품명을 입력해주세요.',
  (v: string) => v && v.length <= 20 || '상품명은 최대 20자까지 입력할 수 있습니다.',
]
export const osStandardRules  =[
  (v: string) => !!v || '규격을 입력해주세요.',
  (v: string) => v && v.length <= 20 || '상품 규격은 최대 20자까지 입력할 수 있습니다.',
]
export const osQuantityRules = [
  (v: string) => !!v || '수량을 입력해주세요.',
  (v: string) => {
    const num = Number(v);
    return Number.isInteger(num) || '수량은 정수로 입력해주세요.';
  },
  (v: string) => {
    const num = Number(v);
    return num < 1e9 || '수량은 9자리 이하의 값이어야 합니다.';
  },
  (v: string) => {
    const num = Number(v);
    return num >= 0 || '수량은 0 이상의 값이어야 합니다.';
  },
];
export const osItemPriceRules = [
  (v: string) => !!v || '가격을 입력해주세요.',
  (v: string) => {
    const num = Number(v);
    return Number.isInteger(num) || '가격은 정수로 입력해주세요.';
  },
  (v: string) => {
    const num = Number(v);
    return num >= 0 || '가격은 0 이상의 값이어야 합니다.';
  },
  (v: string) => {
    const num = Number(v);
    return num < 1e9 || '가격은 9 자리 이하의 값이어야 합니다.';
  },
];

export const compZip = [
  (v: string) => !!v || '우편번호는 필수 항목입니다.',
  (v: string) => v.length === 5 || '우편번호는 5자 입니다.'
];

export const compAddr1 = [
  (v: string) => !!v || '주소는 필수 항목입니다.',
  (v: string) => v.length <= 50 || '주소는 50자 이내로 작성해주세요.'
];

export const compAddr2 = [
  (v: string) => !!v || '상세주소는 필수 항목입니다.',
  (v: string) => v.length <= 50 || '상세주소는 50자 이내로 작성해주세요.'
];

export const compEmail = [
  (v: string) => !!v || '이메일은 필수 항목입니다.',
  (v: string) => /.+@.+\..+/.test(v) || '유효한 이메일 형식이어야 합니다.',
  (v: string) => v.length <= 45 || '이메일은 45자 이내로 작성해주세요.'
];

export const compStartDate = [
  (v: string) => !!v || '생년월일은 필수 항목입니다.',
];




export const compCeoPhoneRules = [
  (v: string) => !!v || '대표자 연락처는 필수 항목입니다.',
];
export const compTellRules = [
  (v: string) => !!v || '전화번호는 필수 항목입니다.',
];