<template>
  <v-navigation-drawer location="right" disable-resize-watcher v-model="store.state.drawer" id="MainNavi">
    <v-list style=" padding:0">
      <v-list-item style="border-top: 1px solid #E9EAEC; height: 50px;" class="naviDate">

        <v-list-item-title>
          <div class="naviTop" style="float:left; margin-top:10px;">
            <v-avatar size="30" color="#B00020" style="cursor: pointer;" @click="toggleDrawer">
              <v-icon>{{ mdiArrowRightThick }}</v-icon></v-avatar>
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item-title style="border-bottom :1px">

        <div class="naviTop naviTopDate" style=" display: flex; align-items: center; justify-content: center; margin-bottom: 20px;">
  
          <b class="fontNotoSans700" style="font-size: 18px;">
          {{ mainDate }}
          </b>
         
        </div>
      </v-list-item-title>
    </v-list>

    <v-list density="compact" nav id="zzimList" class="fontNotoSans400" v-if="zzimListArr.length !== 0">
      <v-list-item v-for="(zzim, iu_idx) in zzimListArr" :key="iu_idx" class="zzimListItem">
        <v-list-item-title style="display: flex; align-items: center; ">
          <span class="fontNotoSans700" style="margin-right: 5px;">{{ iu_idx + 1 }}.</span>
          <span style="font-size: 14px; " class="fontNotoSans400 line-clamp-2">{{ zzim.iu_name }}</span>
        </v-list-item-title>
        <span class="count-transition" :style="{ color: `rgb(0, ${150 - zzim.iu_count * 5}, 0)` }">
          <v-icon style="font-size: 14px; margin-left: 14px;">
            {{ mdiSubdirectoryArrowRight }}
          </v-icon>
          x<input type="number" maxlength="4" v-model="zzim.iu_count" @change="updateCount(zzim)"
            style="width:60px; border-bottom: 1px solid #d2d2d2; padding-left: 5px; margin-right: 10px;" />
            <button @click="imgModal(zzim.iu_photo)">상품보기</button>
            <v-avatar size="20" style="cursor: pointer; margin-left:5px; float: right;" @click="removeZzimItem(zzim)">❌</v-avatar>
        </span>
      </v-list-item>
      <table class="tar" style="float: right;">
        <tbody class="NaviCalc tar">
          <tr>
            <td>공급가액 : </td>
            <td style="padding-left: 20px;">{{ addComma(zzimSumSupply) }} 원</td>
          </tr>
          <tr>
            <td>VAT : </td>
            <td>{{ addComma(zzimSumVat) }} 원</td>
          </tr>
          <tr style="font-weight: 700 !important; height: 35px;">
            <td>총 합 : </td>
            <td>{{ addComma(zzimSumVat+zzimSumSupply) }} 원</td>
          </tr>
        </tbody>
      </table>
    </v-list>

    <div class="fontNotoSans700" v-if="zzimListArr.length === 0"
      style="height: 80%; width:100%; display: flex; justify-content: center; align-items: center;">
      장바구니에 물건을 넣어주세요.
    </div>
  
    <template v-slot:append>
      
      <div class="pa-2">
        <v-btn class="naviLast" block @click="jumunCreate" id="LogoutBtn">
          주문대기상태로 보내기
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
  
  <!--상품이미지 모달-->
    <v-dialog
      v-model="imgModalStatus"
      width="auto"
    >
      <v-card
        max-width="auto"
      >
        <template v-slot:actions>
          <div class="mImgBox">
            <v-img v-if="imgUrlFromModal && imgUrlFromModal.includes('/_files')" height="100%" width="auto" :src="`http://www.xn--hu1bo4e.com/` + imgUrlFromModal || ''" cover ></v-img>
            <v-img v-else-if="imgUrlFromModal && !imgUrlFromModal.includes('/_files')" height="100%" width="auto" :src="currentUrl + `/public/static/itemPhoto/` + imgUrlFromModal || ''" cover ></v-img>
            <img width="100px" v-else src="@/assets/pictures/not_img.png" />
          </div>
        </template>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import router from '@/router';
import {
  mdiArrowRightThick,
  mdiSubdirectoryArrowRight,
} from '@mdi/js'
import axios from 'axios';
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const zzimListArr = ref([] as any[]); // 기본값은 '전체'
const toggleDrawer = () => {
  store.commit('setDrawer', !store.state.drawer);
};
const mainDate = computed(() => store.state.mainDate);
const imgModalStatus = ref(false);
const imgUrlFromModal = ref('');
const currentUrl = 'http://49.247.8.206:3001'

const imgModal = (photo: any) => {
  imgModalStatus.value = !imgModalStatus.value;
  imgUrlFromModal.value = photo;
};
const account = ref({
  id: null,
  name: '',
  role: '',
});

const updateCount = (zzim: any) => {
  store.commit('setZzimListArr', { zzim, newValue: zzim.iu_count });
};

const updateZzimList = () => {
  zzimListArr.value = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
};

const removeZzimItem = (zzim: any) => {
  store.commit('removeFromZzimList', zzim);
  updateZzimList();
};

watch(() => store.state.zzimListArr, () => {
  updateZzimList();
});

const changeMainJogun = computed(() => {
  const changeMain = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
  return changeMain;
});

watch(changeMainJogun, () => {
  //실시간으로 날짜, 기관 변경 감지
  updateZzimList()
});
const empId = computed(() => store.state.userInfoArr.emp_id);

onMounted(async () => {
  account.value = store.state.userInfoArr;
  zzimListArr.value = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate);
});


const zzimCalc_vat = computed(() => {
  var result = 0;
  for (const zzim of zzimListArr.value) {
        result += (zzim.iu_vat_price * zzim.iu_count)
      }
  return result;
});
const zzimCalc_supply = computed(() => {
  var result = 0;
  for (const zzim of zzimListArr.value) {
        result += (zzim.iu_supply_price * zzim.iu_count)
      }
  return result;
});
function addComma(number: any) {
  if (isNaN(number)) return number; // 숫자가 아닌 경우 그대로 반환
  return number.toLocaleString(); // 숫자인 경우 컴마를 추가한 문자열 반환
}
const zzimSumVat = ref(zzimCalc_vat);
const zzimSumSupply = ref(zzimCalc_supply);


const jumunCreate = async () => {
  if (zzimListArr.value.length === 0) {
    return;
  }
  for (const zzim of zzimListArr.value) {
    if (zzim.iu_count <= 0) {
    alert('수량은 1 이상이어야 합니다.');
    return;
}
}
  try {
    console.log(zzimListArr.value, 'zzimListArr.value');
    const response = await axios.post(`/api/jumunCreate/${empId.value}`, { zzimList: zzimListArr.value });
    const result = response.data;

    if (result.success === true) {

      // store.state.zzimListArr에서 필터링하여 조건에 맞는 요소를 찾아 삭제
      const compIdToDelete = store.state.compInfoArr.comp_id;
      const zzimDateToDelete = store.state.mainDate;

      store.state.zzimListArr = store.state.zzimListArr.filter((item: { comp_id: any; zzimDate: any; }) => {
        return !(item.comp_id === compIdToDelete && item.zzimDate === zzimDateToDelete);
      });

      // 저장된 값을 제외한 나머지 zzimListArr이 담겨야 함
      const filteredZzimListArr = store.state.zzimListArr.filter((item: { comp_id: string; zzimDate: string; }) => 
        !(item.comp_id === store.state.compInfoArr.comp_id && item.zzimDate === store.state.mainDate)
      );

      // 새로 필터링된 배열을 저장
      store.state.zzimListArr = filteredZzimListArr;

      // 드로워 닫기
      store.commit('setDrawer', !store.state.drawer);

      const confirmed = window.confirm('완료하였습니다. 주문대기 화면으로 이동하시겠습니까?');
      if (confirmed) {
        await router.push('/headMenu3');
      }
    } else {
      alert('주문대기로 이동 에러 발생');
    }
  } catch (error) {
    alert('오류가 발생하였습니다.');
    console.error(error);
  } finally {
    // zzimList가 빈 배열일 경우 sessionStorage에서 삭제
    if (store.state.zzimListArr.length === 0) {
      sessionStorage.removeItem('zzimList');
    }
  }
};


</script>

<style scoped>
@keyframes colorChange {
  0% {
    color: rgb(100, 0, 0);
  }

  100% {
    color: rgb(0, 12, 150);
  }
}

.color-animation {
  animation: colorChange 0.5s alternate infinite;
  /* 애니메이션 적용, 0.5초 동안 왕복하며 무한 반복 */
}
.line-clamp-2 {
  /* 두 줄로 텍스트를 자르고 개행하도록 스타일을 지정합니다. */
  white-space: normal !important; /* 텍스트가 개행되도록 설정합니다. */
  line-height: 1.2; /* 행간 간격을 조절합니다. */
}
</style>