<template>
  <v-container>
    <v-row >
      <!-- <v-col
        v-for="count in 1"
        :key="count"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"> -->
        <v-col
        v-for="count in 1"
        :key="count"
        xl="12"
        lg="12"
        md="12"
        sm="12"
        cols="12">
        <v-card>
          메인대쉬보드
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>


</script>