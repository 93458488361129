import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MainLayout from './components/layout/MainLayout.vue'
import AuthFormLayout from './components/layout/AuthFormLayout.vue'
import MgtLayout from './components/layout/mgt/MgtLayout.vue'

// Pages
import store from './store';
import indexPage from './pages/indexPage.vue'
import HeadMenu1 from './pages/main/HeadMenu1.vue'
import HeadMenu2 from './pages/main/HeadMenu2.vue'
import HeadMenu3 from './pages/main/HeadMenu3.vue'
import HeadMenu4 from './pages/main/HeadMenu4.vue'
import LoginPage from './pages/auth/LoginPage.vue'
import SignUpPage from './pages/auth/SignUpPage.vue'
import MgtIndexPage from './pages/mgt/MgtIndexPage.vue'
import MgtItemJumun from './pages/mgt/MgtItemJumun.vue'
import MgtItemList from './pages/mgt/MgtItemList.vue'
import MgtUserList from './pages/mgt/MgtUserList.vue'

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/indexPage',
  },
  { 
    path: '/indexPage',
    component: indexPage,
    name: 'indexPage',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/LoginPage',
    component: LoginPage,
    name: 'LoginPage',
    meta: { layout: AuthFormLayout, requiresAuth: false } // 로그인 페이지는 인증이 필요하지 않음 
  },
  { 
    path: '/SignUpPage',
    component: SignUpPage,
    name: 'SignUpPage',
    meta: { layout: AuthFormLayout, requiresAuth: false } // 회원가입 페이지는 인증이 필요하지 않음 
  },
  { 
    path: '/HeadMenu1',
    component: HeadMenu1,
    name: 'HeadMenu1',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/HeadMenu2',
    component: HeadMenu2,
    name: 'HeadMenu2',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/HeadMenu3',
    component: HeadMenu3,
    name: 'HeadMenu3',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/HeadMenu4',
    component: HeadMenu4,
    name: 'HeadMenu4',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/MgtIndexPage',
    component: MgtIndexPage,
    name: 'MgtIndexPage',
    meta: { layout: MgtLayout , requiresAuth: true, requiresAdmin: true, isAdmin: true } // 관리자페이지
  },
  { 
    path: '/MgtItemJumun',
    component: MgtItemJumun,
    name: 'MgtItemJumun',
    meta: { layout: MgtLayout , requiresAuth: true, requiresAdmin: true, isAdmin: true } // 관리자페이지
  },
  { 
    path: '/MgtItemList',
    component: MgtItemList,
    name: 'MgtItemList',
    meta: { layout: MgtLayout , requiresAuth: true, requiresAdmin: true, isAdmin: true } // 관리자페이지
  },
  { 
    path: '/MgtUserList',
    component: MgtUserList,
    name: 'MgtUserList',
    meta: { layout: MgtLayout , requiresAuth: true, requiresAdmin: true, isAdmin: true } // 관리자페이지
  },
]
 

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const isAdmin = store.getters.isAdmin;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if (requiresAuth && !isLoggedIn) {
    next('/LoginPage');
  } else if (requiresAdmin && !isAdmin) {
    alert('관리자 권한이 필요합니다.');
    next('/LoginPage');
  } else if (to.name === 'LoginPage' && isLoggedIn) {
    next('/indexPage');
  } else {
    next();
  }
});

export default router