<template>
  <v-app id="inspire">
    <v-container class="mgtContainer">
    <v-navigation-drawer v-model="drawer" disable-resize-watcher>
      <v-list style="margin: 0; padding: 0;">
        <v-list-item
          :style="listItemStyle"
          @click="navigateTo('/MgtIndexPage', '관리자페이지')"
          :class="{ 'active-link': currentPageTitle === '관리자페이지' }"
          class="fontNotoSans700"
        >
          관리자페이지
        </v-list-item>
        <v-list-item
          @click="navigateTo('/MgtUserList', '업체관리')"
          :class="{ 'active-link': currentPageTitle === '업체관리' }"
          class="fontNotoSans700"
        >
          업체관리
        </v-list-item>
        <v-list-item
          @click="navigateTo('/MgtItemList', '상품관리')"
          :class="{ 'active-link': currentPageTitle === '상품관리' }"
          class="fontNotoSans700"
        >
          상품관리
        </v-list-item>
        <v-list-item
          @click="navigateTo('/MgtItemJumun', '주문관리')"
          :class="{ 'active-link': currentPageTitle === '주문관리' }"
          class="fontNotoSans700"
        >
          주문관리
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>{{ currentPageTitle }}</v-app-bar-title>
    </v-app-bar>

    <v-main>
        <slot />
    </v-main>
  </v-container>
  </v-app>
</template>

<style scoped>
.active-link {
  background-color: #ddd; /* 원하는 활성화된 링크의 배경색을 여기에 설정하세요 */
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import router from '@/router';

const drawer = ref(false);
const currentPageTitle = ref(localStorage.getItem('currentPageTitle') || '관리자페이지');

const listItemStyle = {
  letterSpacing: '-0.5px',
  fontSize: '20px',
  height: '64px',
  backgroundColor: 'goldenrod',
};

const navigateTo = (path, title) => {
  router.push(path);
  currentPageTitle.value = title;
  localStorage.setItem('currentPageTitle', title);
  drawer.value = false; // Optional: Close the drawer after navigation
};

onMounted(() => {
  const storedTitle = localStorage.getItem('currentPageTitle');
  if (storedTitle) {
    currentPageTitle.value = storedTitle;
  }
});
</script>
