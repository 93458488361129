<template>
  <v-main class="blue-grey lighten-4" style="padding: 0;">
    <v-container class="login_container" >
          <v-card class="signup_card" elevation="3"> <!--elevation : 그림자-->
            <div class="pa-3">
              <h1 style="text-align: center" class="mb-10">Sign Up</h1>
              <v-form action="submit" method="post" @submit.prevent="signUp">
                <v-row>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiAccount" 
                      variant="outlined" 
                      label="ID" 
                      type="text" 
                      v-model="form.emp_id" 
                      autocomplete="username" 
                      maxlength="20"
                      :rules="idRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiRenameBox" 
                      variant="outlined" 
                      label="Name" 
                      hint="5자 이내로 작성하세요." 
                      type="text" 
                      v-model="form.emp_name" 
                      maxlength="5"
                      :rules="nameRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiLockOutline" 
                      variant="outlined" 
                      label="PW" 
                      hint="20자 이내로 작성하세요." 
                      type="password" 
                      v-model="form.emp_pw" 
                      maxlength="20"
                      :rules="pwRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiLock" 
                      variant="outlined" 
                      label="PW Check" 
                      hint="비밀번호를 확인해주세요." 
                      type="password" 
                      v-model="form.emp_pw_check" 
                      maxlength="20"
                      :rules="pwRule"

                    />
                  </v-col>
                  
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiEmailVariant" 
                      variant="outlined" 
                      label="Email" 
                      hint="이메일 형식을 맞춰주세요." 
                      type="email" 
                      v-model="form.emp_email" 
                      maxlength="30"
                      :rules="emailRule"

                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiCellphone" 
                      variant="outlined" 
                      label="Phone" 
                      hint="숫자만 입력해주세요." 
                      type="text" 
                      v-model="form.emp_cp" 
                      maxlength="11"
                      :rules="phoneNumberRule"

                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-btn
                    v-if="isFormValid"
                      type="submit"
                      color="blue lighten-1 text-capitalize"
                      depressed
                      large
                      block
                      dark
                      class="mb-3"
                    >
                      OK
                  </v-btn>
                  <v-btn
                    v-if="!isFormValid"
                      color="blue lighten-1 text-capitalize"
                      depressed
                      large
                      block
                      dark
                      class="mb-3"
                    >
                      OK
                  </v-btn>
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                  <router-link to="/LoginPage">
                  <v-btn
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                  >
                    cancel
                  </v-btn>
                </router-link>
                  </v-col>
                  
                </v-row>
                
               
              </v-form>
            </div>
          </v-card>
    </v-container>
  </v-main>
  
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import axios from 'axios';
import router from '../../router';
// import store from '../.././store';
import { 
  idRule,
  emailRule,
  pwRule, 
  nameRule, 
  phoneNumberRule, 
} 
from '@/composables/rules';
import { 
  mdiAccount,
  mdiLock,
  mdiLockOutline,
  mdiRenameBox,
  mdiEmailVariant,
  mdiCellphone
} from '@mdi/js'

const form = ref({
emp_id: '',
emp_pw: '',
emp_pw_check: '',
emp_name: '',
emp_email: '',
emp_cp: '',
emp_role: 'user',
emp_status: 1,
});

const isFormValid = computed(() => {
  return (
    form.value.emp_id.trim() !== '' &&
    form.value.emp_name.trim() !== '' &&
    form.value.emp_pw.trim() !== '' &&
    form.value.emp_email.trim() !== '' &&
    form.value.emp_cp.trim() !== ''
  );
});
const signUp = async () => {
  try {
const checkAndLog = (rule:any, value:any, ruleName:any) => {
  const ruleResult = rule(value);
  if (typeof ruleResult === 'string') {
    alert(`${ruleName}을(를) 확인하세요.`);
    return true;
  } else {
    return false;
  }
};

const isIdValid = idRule.some(rule => checkAndLog(rule, form.value.emp_id, 'ID'));
if (isIdValid) return;

const isNameValid = nameRule.some(rule => checkAndLog(rule, form.value.emp_name, '성명'));
if (isNameValid) return;

const isPwValid = pwRule.some(rule => checkAndLog(rule, form.value.emp_pw, '비밀번호'));
if (isPwValid) return;

const isEmailValid = emailRule.some(rule => checkAndLog(rule, form.value.emp_email, '이메일'));
if (isEmailValid) return;

const isCpValid = phoneNumberRule.some(rule => checkAndLog(rule, form.value.emp_cp, '휴대폰번호'));
if (isCpValid) return;


    const response = await axios.post('/api/signUp', { form: form.value });
    const result = response.data;
    if (result.success === true) {
      alert('회원가입 완료!');
      router.push('/LoginPage');
    } else if(result.success === false) {
      alert('중복된 ID입니다.');
    } else {
      alert('회원가입 에러발생');
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};

</script>



<style scoped>
</style>