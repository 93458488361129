<template>
  <v-main class="blue-grey lighten-4" style="padding: 0;">
    <v-container class="login_container" >
          <v-card class="signup_card" elevation="3"> <!--elevation : 그림자-->
            <div class="pa-3">
              <h1 style="text-align: center" class="mb-10">Registration</h1>
              <v-form action="submit" method="post" @submit.prevent="signUpComp">
                <v-row>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiBank" 
                      variant="outlined" 
                      label="기관 명" 
                      hint="20자 이내로 작성하세요." 
                      type="text" 
                      v-model="form.comp_name" 
                      maxlength="20"
                      :rules="compNameRule"
                    />
                  </v-col>
               
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiCallSplit" 
                      variant="outlined" 
                      label="기관 구분" 
                      hint="10자 이내로 작성하세요." 
                      type="text" 
                      v-model="form.comp_gubun" 
                      maxlength="10"
                      :rules="compGubunRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiEmailVariant" 
                      variant="outlined" 
                      label="Email" 
                      hint="이메일 형식을 맞춰주세요." 
                      type="email" 
                      v-model="form.comp_email" 
                      maxlength="30"
                      :rules="emailRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiPhoneClassic" 
                      variant="outlined" 
                      label="지역 전화번호" 
                      hint="숫자만 입력해주세요." 
                      type="text" 
                      v-model="form.comp_tell" 
                      maxlength="11"
                      :rules="phoneNumberRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiAccount" 
                      variant="outlined" 
                      label="대표자 성명" 
                      hint="한글로 입력해주세요." 
                      type="text" 
                      v-model="form.comp_boss" 
                      maxlength="5"
                      :rules="nameRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiCellphone" 
                      variant="outlined" 
                      label="대표자 연락처" 
                      hint="숫자만 입력해주세요." 
                      type="text" 
                      v-model="form.comp_boss_cp" 
                      maxlength="11"
                      :rules="phoneNumberRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiContacts" 
                      variant="outlined" 
                      label="담당 영양사" 
                      hint="영양사 성명을 입력하세요." 
                      type="text" 
                      v-model="form.emp_name" 
                      maxlength="5"
                      :rules="nameRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-text-field 
                      :prepend-inner-icon="mdiContacts" 
                      variant="outlined" 
                      label="기관 ID" 
                      type="text" 
                      v-model="form.comp_id" 
                      maxlength="20"
                      :rules="idRule"
                    />
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                    <v-btn
                    v-if="isFormValid"
                      type="submit"
                      color="blue lighten-1 text-capitalize"
                      depressed
                      large
                      block
                      dark
                      class="mb-3"
                    >
                      OK
                  </v-btn>
                  <v-btn
                    v-if="!isFormValid"
                      color="blue lighten-1 text-capitalize"
                      depressed
                      large
                      block
                      dark
                      class="mb-3"
                    >
                      OK
                  </v-btn>
                  </v-col>
                  <v-col xl="6" lg="6" md="6" sm="12" cols="6">
                  <router-link to="/LoginPage">
                  <v-btn
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                  >
                    cancel
                  </v-btn>
                </router-link>
                  </v-col>
                  
                </v-row>
                
               
              </v-form>
            </div>
          </v-card>
    </v-container>
  </v-main>
  
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import axios from 'axios';
import router from '../../router';
// import store from '../.././store';
import { 
  idRule,
  emailRule,
  nameRule, 
  phoneNumberRule, 
  compGubunRule,
  compNameRule
} 
from '@/composables/rules';
import { 
  mdiBank,
  mdiCallSplit,
  mdiPhoneClassic,
  mdiAccount,
  mdiContacts,
  mdiEmailVariant,
  mdiCellphone
} from '@mdi/js'

const form = ref({
  comp_id: '',
  comp_name: '',
  emp_name: '',
  comp_email: '',
  comp_tell: '',
  comp_boss: '',
  comp_boss_cp: '',
  comp_gubun: '',
  comp_status: 1,
});

const isFormValid = computed(() => {
  return (
    form.value.comp_name.trim() !== '' &&
    form.value.emp_name.trim() !== '' &&
    form.value.comp_email.trim() !== '' &&
    form.value.comp_tell.trim() !== '' &&
    form.value.comp_boss.trim() !== ''&&
    form.value.comp_boss_cp.trim() !== '' &&
    form.value.comp_gubun.trim() !== '' 
  );
});
const signUpComp = async () => {
  try {
const checkAndLog = (rule:any, value:any, ruleName:any) => {
  const ruleResult = rule(value);
  if (typeof ruleResult === 'string') {
    alert(`${ruleName}을(를) 확인하세요.`);
    return true;
  } else {
    return false;
  }
};
const isCompNameValid = compNameRule.some(rule => checkAndLog(rule, form.value.comp_name, '기관 명'));
if (isCompNameValid) return;

const isDietNameValid = nameRule.some(rule => checkAndLog(rule, form.value.emp_name, '영양사 성명'));
if (isDietNameValid) return;

const isEmailValid = emailRule.some(rule => checkAndLog(rule, form.value.comp_email, '이메일'));
if (isEmailValid) return;

const isTellValid = phoneNumberRule.some(rule => checkAndLog(rule, form.value.comp_tell, '전화번호'));
if (isTellValid) return;

const isBossValid = nameRule.some(rule => checkAndLog(rule, form.value.comp_boss, '대표자 성명'));
if (isBossValid) return;

const isBossCpValid = phoneNumberRule.some(rule => checkAndLog(rule, form.value.comp_boss_cp, '대표자 휴대폰 번호'));
if (isBossCpValid) return;

const isGubunValid = compGubunRule.some(rule => checkAndLog(rule, form.value.comp_gubun, '기관구분'));
if (isGubunValid) return;

const isCompIdValid = idRule.some(rule => checkAndLog(rule, form.value.comp_id, '기관 ID'));
if (isCompIdValid) return;


    const response = await axios.post('/api/signUpComp', { form: form.value });
    const result = response.data;
    if (result.success === true) {
      alert('기관 등록 완료!');
      router.push('/LoginPage');
    } else if(result.success === false) {
      alert('중복된 기관입니다.');
    } else {
      alert('기관 등록 에러 발생');
    }
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};

</script>



<style scoped>
</style>