<template>
  <v-card>
    <v-row>
      <v-col cols="12">
        <table class="jogunDateTable">
          <tbody>
            <tr>
              <td class="compJogunSelectTd">업체 / 구분 검색</td>
              <td class="compJogunSelectSecontTd">
                <div style="display: flex;">
                  <v-text-field class="itemPageSearchInput" style="min-width:100%;"
                    v-model="search" color="#0f7545" label="검색" single-line clearable :append-inner-icon="mdiMagnify" flat
                    hide-details="auto" variant="outlined" density="compact">
                  </v-text-field>
                </div>
              </td>
              <td class=""></td>
              <td class="">
              </td>
              <td class="tar "></td>
              <td class="jumunRegistTd">
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
            
          <v-card style=" max-height: calc(100vh - 150px);">
          <div>
            <v-data-table 
              fixed-header 
              :headers="headers" 
              :items-per-page = "30"
              :items="filteredItems" 
              :items-per-page-options="[
                { value: 30, title: '30' },
                { value: 50, title: '50' },
                { value: 100, title: '100' },
                { value: 200, title: '200' },
                { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
              ]"
              item-value="name" 
              height="auto" 
              width="auto" 
              scrollable 
              class="memberListTable fontNotoSans400"
              style=" max-height: 90vh; height: calc(100vh - 200px); overflow-y: hidden; position: relative;"
            >
            <template v-slot:item="{ item, index }">
                <tr class="iuToggleTr">
                  <td class="tac" style="max-width:50px;">{{ index + 1 }}</td>
                  <td class="tac" style="max-width:300px;">{{ item.comp_name || '' }}<br/>{{ item.comp_id }}</td>
                  <td class="tac" style="max-width:100px;">{{ item.comp_gubun || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ item.emp_name || '' }} | {{ item.emp_id || '' }} <br/> {{ item.emp_cp || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ removeZeroDecimal(item.comp_percent) || 0 }}%</td>
                  <td class="tac" style="max-width:110px;">{{ item.comp_boss || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ item.comp_email || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ formatDateTime(item.comp_wdate) || '' }}</td>
                  <td class="tac" style="max-width:110px;">{{ item.status_text || '' }}</td>
                  <td class="tac" style="max-width:110px;">
                    <button class="mgtUpdateBtn" @click="updateComp(item)">수정</button>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
        <v-row></v-row>
      </v-card>
  <v-dialog
    v-model="modalStatus"
    width="auto"
  >
    <v-card
      max-width="auto"
    >
      <template v-slot:actions>
        <v-card>
          <h2 style="margin-bottom: 10px;" class="tac fontNotoSans400">고객정보수정</h2>
          <v-table class="itemUpdateTable">
            <tbody>
              <tr>
                <td>업체 명</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_name" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="30">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>구분</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_gubun" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="30">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>대표자</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_boss" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>대표자 휴대폰</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field type="number" style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_boss_cp" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="11">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>업체 전화번호</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field type="number" style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_tell" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="11">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>업체 이메일</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_email" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="50">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>판매가 증감(%)</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field type="number" style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_percent" color="#000" hide-details="auto" variant="outlined"
                      density="compact" maxlength="10">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>상태</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <select v-model="compInfoForm.comp_status">
                      <option value="1">인증</option>
                      <option value="0">미인증</option>
                      <option value="2">임시정지</option>
                      <option value="3">탈퇴</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="border-bottom: 2px solid #0f7545 !important;">가입일</td>
                <td style="border-bottom: 2px solid #0f7545 !important;">
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.comp_wdate" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" readonly>
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="border-top: 2px solid #0f7545 !important;">담당자 아이디<br><span style="font-weight: 400; font-size: 12px !important;">※아이디를 정확히 확인 후 기입해주세요.</span></td>
                <td style="border-top: 2px solid #0f7545 !important;">
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.emp_id" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="100">
                    </v-text-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td>담당자</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.emp_name" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="10" readonly>
                    </v-text-field>
                  </div>
                </td>
              </tr>
              
              <tr>
                <td>담당자 휴대폰</td>
                <td>
                  <div style="display: flex; align-items: center;">
                    <v-text-field style="max-width:300px; width:300px; min-width:100px; border-radius: 0; "
                      v-model="compInfoForm.emp_cp" color="#000"  hide-details="auto" variant="outlined"
                      density="compact" maxlength="100" readonly>
                    </v-text-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions>
            <v-btn color="#424242" variant="flat" @click="modalFalse" class="fontNotoSans400">취소</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#0f7545" variant="flat" @click="mgtCompUpdate" class="fontNotoSans400">수정</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-card>
    
  </v-dialog>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref, onMounted, computed, reactive } from 'vue';
import {
  mdiMagnify,
} from '@mdi/js'
const search = ref('');

const headers = ref([
  { title: 'No.', key: '', align: 'center' },
  { title: '업체 명', key: 'comp_name', align: 'center', sortable: true },
  { title: '구분', key: 'comp_gubun', align: 'center', sortable: true },
  { title: '담당자', key: 'emp_name', align: 'center', sortable: true },
  { title: '판매가 증감', key: '', align: 'center', sortable: true },
  { title: '대표자', key: 'comp_boss', align: 'center', sortable: true },
  { title: '업체 이메일', key: 'comp_email', align: 'center', sortable: true },
  { title: '가입일', key: 'comp_wdate', align: 'center', sortable: true },
  { title: '상태', key: 'status_text', align: 'center', sortable: true },
  { title: '상세', key: '', align: 'center', sortable: true },
] as any[]);

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return compInfoArr.value;
  }
  return compInfoArr.value.filter(item =>
    (item.comp_name && item.comp_name.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.status_text && item.status_text.toLowerCase().includes(search.value.toLowerCase())) 
  );
});
function formatDateTime(inputDateString:any) {
    const date = new Date(inputDateString);

    // 원하는 형식에 맞게 날짜와 시간을 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 두 자리로 패딩
    const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 패딩
    const hours = String(date.getHours()).padStart(2, '0'); // 시를 두 자리로 패딩
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 분을 두 자리로 패딩

    // 변경된 형식으로 날짜와 시간을 조합하여 반환
    return `${year}/${month}/${day} ${hours}:${minutes}`;
}

const compInfoForm = reactive({
  comp_idx: 0,
  comp_id: '',
  comp_name: '',
  emp_id: '',
  emp_name: '',
  emp_cp: '',
  comp_email: '',
  comp_tell: '',
  comp_boss: '',
  comp_boss_cp: '',
  comp_gubun: '',
  comp_status: '',
  comp_wdate: '',
  status_text: '',
  comp_percent: 0
});
const modalStatus = ref(false);

const updateComp = (item:any) => {
  modalStatus.value = !modalStatus.value;
  compInfoForm.comp_idx = item.comp_idx
  compInfoForm.comp_id = item.comp_id
  compInfoForm.comp_name = item.comp_name
  compInfoForm.emp_id = item.emp_id
  compInfoForm.emp_name = item.emp_name
  compInfoForm.emp_cp = item.emp_cp
  compInfoForm.comp_email = item.comp_email
  compInfoForm.comp_tell = item.comp_tell
  compInfoForm.comp_boss = item.comp_boss
  compInfoForm.comp_boss_cp = item.comp_boss_cp
  compInfoForm.comp_gubun = item.comp_gubun
  compInfoForm.comp_status = item.comp_status
  compInfoForm.status_text = item.status_text
  compInfoForm.comp_percent = item.comp_percent
  compInfoForm.comp_wdate = formatDateTime(item.comp_wdate)
};

const modalFalse = () => {
  modalStatus.value = !modalStatus.value;
};
function removeZeroDecimal(number:number) {
    // 숫자를 문자열로 변환
    var strNumber = number.toString();

    // 소수점 이하가 0인지 확인
    if (strNumber.charAt(strNumber.length - 1) === '0') {
        // 소수점 이하가 0이면 제거하고 반환
        return strNumber.slice(0, -2);
    } else {
        // 소수점 이하가 0이 아니면 그대로 반환
        return strNumber;
    }
}
const compInfoArr = ref([] as any[]);
const compInfoArr2 = ref([] as any[]);

const mgtSelectAllComp = async () => {
  try {
    const response = await axios.get('/api/mgtSelectAllComp');
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      compInfoArr.value = responseData;
      compInfoArr2.value = responseData;

    console.log(compInfoArr.value,'compInfoArr.value')

      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};


  const mgtCompUpdate = async () => {
    
  try {
    console.log(compInfoForm,'compInfoForm')
    const confirmed = window.confirm('정말 수정하시겠습니까?');
    if (confirmed) {
      const response = await axios.post('/api/mgtCompUpdate', { form: compInfoForm });
      const result = response.data;
      if (result.success === true) {
        await mgtSelectAllComp();
      } else {
        alert('에러가 발생하였습니다.');
      }
    } else {
      return;
    }
    modalFalse();
  } catch (error) {
    alert('오류');
    console.error(error);
  }
};

onMounted(() => {
  mgtSelectAllComp();
});
</script>