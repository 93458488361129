<template>
  <v-container>
    <v-row >
      <v-col cols="12">
        <div style="height: 500px; display: flex; align-items: center; justify-content: center;" class="fontNotoSans700">
          준비 중 입니다.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">


</script>