<template>
      <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
        <v-tab :value="1" class="fontNotoSans700">회원가입</v-tab>
        <v-tab :value="2" class="fontNotoSans700">업체등록</v-tab>
      </v-tabs>

      <v-window v-model="tab">
        <v-window-item :value="1">
          <SignUpEmp></SignUpEmp>
        </v-window-item>
        <v-window-item :value="2">
          <SignUpComp></SignUpComp>
        </v-window-item>
      </v-window>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SignUpEmp from '../../components/auth/SignUpEmp.vue';
import SignUpComp from '../../components/auth/SignUpComp.vue';

const tab = ref<number>(1); // 탭의 초기값을 지정합니다.
</script>
<style scoped>
.v-main{
  padding-top: 60px;
}
</style>