<template>
    <v-main class="blue-grey lighten-4">
      <v-container class="login_container" >
            <v-card class="login_card" elevation="3"> <!--elevation : 그림자-->
              <div class="pa-10">
                <h1 style="text-align: center" class="mb-10">Login</h1>
                <v-form action="submit" method="post" @submit.prevent="login">
                  <v-text-field :prepend-inner-icon="mdiAccount" variant="outlined" label="ID" type="text" id="loginId" v-model="form.loginId" autocomplete="username" />
                  <v-text-field :prepend-inner-icon="mdiLock" variant="outlined" label="PW" hint="Enter your password to access this website" type="password" id="loginPw" v-model="form.loginPw" autocomplete="current-password" />
                  <v-btn
                    type="submit"
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                    class="mb-3"
                  >
                    LOGIN
                  </v-btn>
                  <router-link to="/SignUpPage">
                    <v-btn
                      color="blue lighten-1 text-capitalize"
                      depressed
                      large
                      block
                      dark
                    >
                      Sign Up
                    </v-btn>
                  </router-link>
                </v-form>
              </div>
            </v-card>
      </v-container>
    </v-main>
    
</template>

<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import router from '../../router';
import store from '../.././store';
import { mdiAccount,mdiLock } from '@mdi/js'
function formatDate(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
const mainDate = ref(formatDate(new Date().toISOString().slice(0, 10)))

const form = ref({
  loginId: '',
  loginPw: ''
});
const login = () => {
  const args = {
    loginId: form.value.loginId,
    loginPw: form.value.loginPw
  };

  axios
    .post("/api/account", args)
    .then(async (res) => {
      if (res.data.success === true) {
        console.log(res.data.member)
        // 변경된 속성에 주의하여 업데이트
        store.dispatch('role', { role: res.data.member.emp_role });
        store.commit('setUserInfoArr', {
          emp_id: res.data.member.emp_id,
          emp_name: res.data.member.emp_name,
          emp_role: res.data.member.emp_role,
        });
        await selectAllCompInfo(res.data.member.emp_id);

        store.commit('setMainDate',mainDate);
        router.push({
          path: "/",
        });
      } else {
        alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
      }
    })
    .catch(() => {
      alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
    });
};

// compInfo 가져오기
const compInfoArr = ref([] as any[]);

const selectAllCompInfo = async (empId: any) => {
  try {
    const response = await axios.get(`/api/selectAllCompInfo/${empId}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      compInfoArr.value = responseData;
      store.commit('setCompInfoArr', compInfoArr.value[0]);
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('불러오기 실패');
    }
  } catch (error) {
    console.error('불러오기 캐치에러', error);
  }
};


// onMounted(async () => {
//   try {
//     const res = await axios.get("/api/account");
//     account.value = res.data;
//   } catch (error) {
//     return;
//   }
//   const args = {
//     loginId: form.value.loginId,
//     loginPw: form.value.loginPw
//   };
//   axios
//     .post("/api/account", args)
//     .then((res:any) => {
//       account.value = res.data;
//       store.dispatch('login', { role: res.data.role });
//       store.dispatch('logout', { role: res.data.role });
//     });
// });

</script>



